import {Button} from "@/shared/v2";
import React, {ReactElement} from "react";

import {ConfirmExitModal} from "@/shared/v2/modals/confirm-exit-modal";
import {useActionsContext} from "../../contexts/actions-context";
import {useConfirmationBlocker} from "@/hooks";
import {useNavigationContext} from "../../contexts/navigation-context";
import {useSelectedSourcesContext} from "../../contexts/selected-sources-context";

import styles from "./buttons.module.scss";

export const CancelButton = (): ReactElement => {
  const {close} = useNavigationContext();
  const {isDirty: hasChanges} = useSelectedSourcesContext();
  const {isSubmittingRef} = useActionsContext();

  const unsavedChangesModal = useConfirmationBlocker({
		shouldBlock: () => hasChanges && !isSubmittingRef.current,
		allowSameLocation: false,
		renderPrompt: ({isOpen, cancel, proceed}) => (
			<ConfirmExitModal
				title="Confirm Exit"
				description="
					Any changes you've made to your dataset selections haven't been saved.
					Do you want to exit?
				"
				isOpen={isOpen}
				onClose={cancel}
				onConfirm={proceed}
				portal
			/>
		),
	});

  return (
    <>
      <Button
        variant="outlined"
        className={styles.commitButton}
        onClick={close}
      >
        Cancel
      </Button>

      {unsavedChangesModal}
    </>
  );
}
