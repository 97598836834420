import React, {ReactElement, useMemo} from "react";

import {TrainingSetMedia} from "@/models/ai-model";
import {useChatMediaContext} from "@/context/chat-contexts";
import {useSearchContext} from "@/canvas/select-chat-sources-modal/contexts/search-context";
import {MediaTable} from "../../media-table";

import styles from "./select-media-tab.module.scss";

const filterFilesByOriginalFilename = (media: TrainingSetMedia[], search: string) => {
	return media.filter(({originalFilename}) =>
		originalFilename.toLowerCase().includes(search.toLowerCase()),
	);
};

export const SelectMediaTab = (): ReactElement => {
  const {media: allMedia} = useChatMediaContext();
  const {search} = useSearchContext();

  const filteredMedia = useMemo(() => [
		...filterFilesByOriginalFilename(allMedia.files, search),
		...filterFilesByOriginalFilename(allMedia.videos, search),
	], [allMedia, search]);

  return (
    <div className={styles.selectMediaTab}>
			<MediaTable media={filteredMedia} />
    </div>
  );
}
