import { gql } from "@apollo/client";

export const REGENERATE_REPORT = gql`
  mutation RegenerateReport($historyId: GUID!) {
    regenerateAiOrchestrationReport(historyId: $historyId) {
      id
      reportUrl
      reportWordUrl
      processState
    }
  }
`;
