/* eslint-disable react/prop-types */
import React, {ReactElement, useContext, useMemo, useState} from "react";
import {useMutation} from "@apollo/client";
import {
	MultiSelect,
	Option,
	Select,
} from "../../shared";
import {MANAGE_WORKSPACES} from "../../graphql/mutations/mutations";
import {PaginatedTable} from "../../shared/components/table/paginated-table";
import {Column, Row} from "react-table";
import {ToastContext} from "../../context/toast-context";
import {useLoadingQuery} from "../../hooks/useLoadingQuery";
import {GET_ADMIN_FEATURE_WORKSPACES} from "../../graphql/queries/queries";
import {
	Workspace,
	WorkspaceChanges,
	WorkspacesData,
	WorkspaceFilter,
	WorkspacePlan,
	WorkspacesPageData,
	WorkspacesPageVars,
} from "../../models/workspace";
import {Panel} from "../../shared/components/panel";
import {SelectValue} from "../../shared/components/multi-select";
import {FilterDisplay} from "../components/filter-display";
import {Body, Button, DebounceSearch, Modal} from "../../shared/v2";
import styles from "./index.module.scss";
import {FilterIcon, SearchIcon} from "../../icons";

const TABLE_PAGE_SIZE = 100;

const WorkspaceManagement = React.memo((): ReactElement => {
	const {updateToast} = useContext(ToastContext);
	// States
	const [filter, setFilter] = useState<WorkspaceFilter>({});
	const [unsavedFilter, setUnsavedFilter] = useState<{
		aiInsightsEnabled?: string;
		allowAnonymous?: string;
		enableOfflineAssist?: string;
		plan?: WorkspacePlan[];
		chatbotEnabled?: string;
		workflowEnabled?: string;
	}>({});
	const [changes, setChanges] = useState<{
		aiInsightsEnabled: boolean | undefined;
		allowAnonymous: boolean | undefined;
		enableOfflineAssist: boolean | undefined;
		plan: WorkspacePlan | undefined;
		chatbotEnabled: boolean | undefined;
		workflowEnabled: boolean | undefined;
	}>({
		aiInsightsEnabled: undefined,
		allowAnonymous: undefined,
		enableOfflineAssist: undefined,
		plan: undefined,
		chatbotEnabled: undefined,
		workflowEnabled: undefined,
	});
	const [showFilter, setShowFilter] = useState(false);
	const [selectedWorkspaces, setSelectedWorkspaces] = useState<Row<Workspace>[]>([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [selectAll, setSelectAll] = useState(false);
	const [showBulkActions, setShowBulkActions] = useState<boolean>(false);
	const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE);

	const toggleBulkActionModel = (): void => {
		if (showBulkActions) {
			setChanges({
				aiInsightsEnabled: undefined,
				allowAnonymous: undefined,
				enableOfflineAssist: undefined,
				plan: undefined,
				chatbotEnabled: undefined,
				workflowEnabled: undefined,
			});
		}
		setShowBulkActions(!showBulkActions);
	};

	const numFiltersUsed = useMemo(
		() => {
			return Object.keys(unsavedFilter).length;
		},
		[unsavedFilter],
	);

	const loadingQuery = useLoadingQuery<WorkspacesPageData, WorkspacesPageVars>(GET_ADMIN_FEATURE_WORKSPACES, {
		variables: {filter, limit: pageSize || 50},
		errorPolicy: "all",
		what: "Workspaces",
		fetchPolicy: "network-only",
		nextFetchPolicy: "cache-first",
		fetchMoreClassName: styles.fetchMore,
		notifyOnNetworkStatusChange: true,
	});

	const {data, error, refetch, fetchMoreFragment, fragment, handleFetchMore} = loadingQuery;
	/** ******************************
	 * Mutations start below
	 *********************************/
	const [setFeatures] = useMutation<WorkspacesData>(MANAGE_WORKSPACES, {
		onCompleted: changed => {
			updateToast({
				description: `${changed.manageWorkspaces} workspace(s) changed.`,
				type: "informational",
			});
			refetch();
		},
	});
	const updateFeatures = (changesToMake: WorkspaceChanges): void => {
		setFeatures({
			variables: {
				changes: changesToMake,
				useFilter: selectAll,
				filter,
				workspaceIds: selectedWorkspaces.map(w => w.original.id),
			},
		});
	};
	/** ******************************
	 * End of Mutations
	 *********************************/

	const handleUpdate = (): void => {
		updateFeatures({...changes});

		toggleBulkActionModel();
		setSelectedWorkspaces([]);
		setSelectAll(false);
	};

	const handleClearFilters = (): void => {
		setFilter({});
		refetch();
	};
	const handleRemoveFilter = (clearKey, clearValue?): void => {
		const newFilter = {...filter};

		if (Array.isArray(newFilter[clearKey])) {
			newFilter[clearKey] = newFilter[clearKey].filter(
				item => item !== clearValue,
			);
			if (newFilter[clearKey].length === 0) newFilter[clearKey] = undefined;
		} else {
			newFilter[clearKey] = undefined;
		}
		setFilter(newFilter);
		refetch();
	};
	const updateUnsavedFilter = (value, id: string): void => {
		setUnsavedFilter({...unsavedFilter, [id]: value});
	};
	const updateChanges = (value, id: string): void => {
		setChanges({...changes, [id]: value});
	};

	const currentData =

		useMemo(
			() =>
				data?.allWorkspaces?.items?.slice(
					currentPage * pageSize,
					(currentPage + 1) * pageSize,
				),
			[data, currentPage],
		);
	const columns: Column<Workspace>[] = [
		{
			id: "name",
			Header: "Name",
			accessor: "name",
			minWidth: 250,
			Cell: ({value}) => <Body size="s">{value}</Body>,
		},
		{
			Header: "AI Insights Enabled",
			accessor: "aiInsightsEnabled",
			Cell: ({value}) => (
				<Body size="s">{value ? "On" : ""}</Body>
			),
		},
		{
			Header: "Allow Anonymous",
			accessor: "allowAnonymous",
			Cell: ({value}) => (
				<Body size="s">{value ? "On" : ""}</Body>
			),
		},
		{
			Header: "Offline Assist",
			accessor: "enableOfflineAssist",
			Cell: ({value}) => (
				<Body size="s">{value ? "On" : ""}</Body>
			),
		},
		{
			Header: "Plan",
			accessor: "plan",
			Cell: ({value}) => <Body size="s">{value}</Body>,
		},
		{
			Header: "Chatbot Enabled",
			accessor: "chatbotEnabled",
			Cell: ({value}) => (
				<Body size="s">{value ? "On" : ""}</Body>
			),
		},
		{
			Header: "Workflow Enabled",
			accessor: "workflowEnabled",
			Cell: ({value}) => (
				<Body size="s">{value ? "On" : ""}</Body>
			),
		}
	];

	const isFiltered = Object.keys(filter).some(
		value => filter[value] !== undefined,
	);

	const handleSearch = (newValue: string): void => {
		setFilter({...filter, name: newValue});
		setCurrentPage(0); // In case there's only one page of results, want to start at the first page.
	};

	const handlePerPageChange = (newPageSize: number): void => {
		setPageSize(newPageSize);
		setCurrentPage(0);
	}

	return (
		<>
			{error && (
				<p className={styles.err}>
					Error loading workspace list: {error.message}
				</p>
			)}
			{currentData &&
			currentData?.length === 0 &&
			!isFiltered &&
			!fetchMoreFragment ? (
					<></>
				) : (
					<header className={styles.header}>
						<DebounceSearch
							id="search-input"
							value={filter.name || ""}
							onChange={handleSearch}
							placeholder="Search"
							leftIcon={<SearchIcon />}
						/>
						<Button
							leftIcon={<FilterIcon />}
							variant="outlined"
							onClick={() => {
								setUnsavedFilter({
									plan: filter.plan,
									enableOfflineAssist:
									filter.enableOfflineAssist === true
										? "On"
										: filter.enableOfflineAssist === false
											? "Off"
											: undefined,
									aiInsightsEnabled:
									filter.aiInsightsEnabled === true
										? "On"
										: filter.aiInsightsEnabled === false
											? "Off"
											: undefined,
									allowAnonymous:
									filter.allowAnonymous === true
										? "On"
										: filter.allowAnonymous === false
											? "Off"
											: undefined,
									chatbotEnabled:
									filter.chatbotEnabled === true
										? "On"
										: filter.chatbotEnabled === false
											? "Off"
											: undefined,
									workflowEnabled:
									filter.workflowEnabled === true
										? "On"
										: filter.workflowEnabled === false
											? "Off"
											: undefined
								});
								setShowFilter(!showFilter);
							}}
						>
							Filter
						</Button>
						<div className={styles.actions}>
							{selectedWorkspaces.length > 0 && data && (
								<Button
									variant="outlined"
									onClick={toggleBulkActionModel}
								>
									{`Bulk Actions${
										selectAll
											? ` (${
												(data.allWorkspaces.items?.length ?? 0) +
												data.allWorkspaces.remaining
											})`
											: ` (${selectedWorkspaces.length})`
									}`}
								</Button>
							)}
						</div>
					</header>
				)}
			<FilterDisplay
				filter={filter}
				handleClearFilters={handleClearFilters}
				handleRemoveFilter={handleRemoveFilter}
			/>
			{fragment ||
				(data && currentData && data.allWorkspaces.items?.length > 0 ? (
					<>
						<PaginatedTable<Workspace>
							columns={columns}
							data={currentData}
							selectedValues={selectedWorkspaces}
							onSelectChange={setSelectedWorkspaces}
							pageState={[currentPage, setCurrentPage]}
							pageSize={pageSize}
							totalCount={
								(data.allWorkspaces.items?.length ?? 0) +
								data.allWorkspaces.remaining
							}
							handleFetchMore={handleFetchMore}
							fetchMoreFragment={fetchMoreFragment}
							selectAllState={[selectAll, setSelectAll]}
							dataLength={data.allWorkspaces.items?.length}
							onPageSizeChange={handlePerPageChange}
						/>
					</>
				) : (
					<Body size="s">No results found</Body>
				))}
			{showFilter && (
				<Panel
					title="Filter"
					handleClose={() => setShowFilter(false)}
					theme="purple"
				>
					<div className={styles.sidebarContainer}>
						<div className={styles.sidebarProperties}>
							<Select
								id="aiInsightEnabled"
								label="AI Insights"

								options={[
									{text: "Not filtered", value: undefined},
									{text: "On", value: "On"},
									{text: "Off", value: "Off"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "aiInsightsEnabled")
								}
								selectedValue={unsavedFilter.aiInsightsEnabled}
							/>
							<Select
								id="chatbotEnabled"
								label="Chatbot Enabled"

								options={[
									{text: "Not filtered", value: undefined},
									{text: "On", value: "On"},
									{text: "Off", value: "Off"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "chatbotEnabled")
								}
								selectedValue={unsavedFilter.chatbotEnabled}
							/>
							<Select
								id="workflowEnabled"
								label="Workflow Enabled"
								options={[
									{text: "Not filtered", value: undefined},
									{text: "On", value: "On"},
									{text: "Off", value: "Off"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "workflowEnabled")
								}
								selectedValue={unsavedFilter.workflowEnabled}
							/>
							<Select
								id="allowAnonymous"
								label="Allow Anonymous"

								options={[
									{text: "Not filtered", value: undefined},
									{text: "On", value: "On"},
									{text: "Off", value: "Off"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "allowAnonymous")
								}
								selectedValue={unsavedFilter.allowAnonymous}
							/>
							<Select
								id="enableOfflineAssist"
								label="Offline Assist"

								options={[
									{text: "Not filtered", value: undefined},
									{text: "On", value: "On"},
									{text: "Off", value: "Off"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "enableOfflineAssist")
								}
								selectedValue={unsavedFilter.enableOfflineAssist}
							/>
							<MultiSelect
								id="plan"
								key="plan"
								value={unsavedFilter.plan || []}
								label="Workspace Plan"
								options={Object.keys(WorkspacePlan).map(
									(key): SelectValue<WorkspacePlan> => {
										const value = WorkspacePlan[key];
										return {name: value, id: value};
									},
								)}
								onChange={updateUnsavedFilter}
							/>
						</div>
						<div className={styles.sidebarFooter}>
							<Button
								onClick={() => {
									setFilter({
										plan: unsavedFilter.plan,
										enableOfflineAssist:
											unsavedFilter.enableOfflineAssist === "On"
												? true
												: unsavedFilter.enableOfflineAssist === "Off"
													? false
													: undefined,
										aiInsightsEnabled:
											unsavedFilter.aiInsightsEnabled === "On"
												? true
												: unsavedFilter.aiInsightsEnabled === "Off"
													? false
													: undefined,
										chatbotEnabled:
											unsavedFilter.chatbotEnabled === "On"
												? true
												: unsavedFilter.chatbotEnabled === "Off"
													? false
													: undefined,
										allowAnonymous:
											unsavedFilter.allowAnonymous === "On"
												? true
												: unsavedFilter.allowAnonymous === "Off"
													? false
													: undefined,

										workflowEnabled:
											unsavedFilter.workflowEnabled === "On"
												? true
												: unsavedFilter.workflowEnabled === "Off"
													? false
													: undefined,
									});
									setShowFilter(false);
								}}
							>
								{
									numFiltersUsed
										? `Apply Filters (${String(numFiltersUsed)})`
										: "Apply Filters"
								}
							</Button>
							<Button
								variant="outlined"
								onClick={() => {
									setFilter({});
									setShowFilter(false);
								}}
							>
								Clear Filters
							</Button>
						</div>
					</div>
				</Panel>
			)}
			<Modal
				className={styles.workspaceManagementModal}
				isOpen={showBulkActions}
				onClose={toggleBulkActionModel}
				title={`Updating ${
					selectAll
						? (data?.allWorkspaces?.items?.length ?? 0) + (data?.allWorkspaces?.remaining ?? 0)
						: selectedWorkspaces.length
				} Workspaces`}
			>

				<div>
					<Select
						className={styles.modelSelect}
						id="changesAiInsightEnabled"
						label="AI Insights"
						options={[
							{text: "Do not change", value: undefined},
							{text: "On", value: true},
							{text: "Off", value: false},
						]}
						onChange={newValue =>
							updateChanges(newValue, "aiInsightsEnabled")
						}
						selectedValue={changes.aiInsightsEnabled}
					/>
					<Select
						className={styles.modelSelect}
						id="changesAllowAnonymous"
						label="Allow Anonymous"
						options={[
							{text: "Do not change", value: undefined},
							{text: "On", value: true},
							{text: "Off", value: false},
						]}
						onChange={newValue => updateChanges(newValue, "allowAnonymous")}
						selectedValue={changes.allowAnonymous}
					/>
					<Select
						className={styles.modelSelect}
						id="changesEnableOfflineAssist"
						label="Offline Assist"
						options={[
							{text: "Do not change", value: undefined},
							{text: "On", value: true},
							{text: "Off", value: false},
						]}
						onChange={newValue =>
							updateChanges(newValue, "enableOfflineAssist")
						}
						selectedValue={changes.enableOfflineAssist}
					/>
					<Select
						className={styles.modelSelect}
						id="changesPlan"
						label="Workspace Plan"
						options={[
							{text: "Do not change", value: undefined},
							...Object.keys(WorkspacePlan).map(
								(key): Option<WorkspacePlan> => {
									const value = WorkspacePlan[key];
									return {text: value, value};
								},
							),
						]}
						onChange={newValue => updateChanges(newValue, "plan")}
						selectedValue={changes.plan}
					/>
					<Select
						className={styles.modelSelect}
						id="changesChatbotEnabled"
						label="Enable Chatbot"
						options={[
							{text: "Do not change", value: undefined},
							{text: "On", value: true},
							{text: "Off", value: false},
						]}
						onChange={newValue =>
							updateChanges(newValue, "chatbotEnabled")
						}
						selectedValue={changes.chatbotEnabled}
					/>

					<Select
						className={styles.modelSelect}
						id="changesWorkflowEnabled"
						label="Enable Workflow"
						options={[
							{text: "Do not change", value: undefined},
							{text: "On", value: true},
							{text: "Off", value: false},
						]}
						onChange={newValue =>
							updateChanges(newValue, "workflowEnabled")
						}
						selectedValue={changes.workflowEnabled}
					/>
				</div>
				<div className={styles.modelFooter}>
					<Button
						onClick={toggleBulkActionModel}
						variant="outlined"
					>
						Cancel
					</Button>
					<Button
						onClick={handleUpdate}
					>
						Update
					</Button>
				</div>
			</Modal>
		</>
	);
});

WorkspaceManagement.displayName = "WorkspaceManagement";
export {WorkspaceManagement};
