import {TrainingSet} from "@/models";
import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {Body, Checkbox, Separator, Subheader} from "@/shared/v2";
import {FileCorrectIcon} from "@/icons";
import {OverflowTooltip} from "@/shared/v2/tooltip/overflow-tooltip";
import {SelectSourcesPage, useNavigationContext} from "@/canvas/select-chat-sources-modal/contexts/navigation-context";
import {useThemeMode} from "@/context/theme-mode-context";

import styles from "./select-training-set-button.module.scss";
import {useSelectedSourcesContext} from "@/canvas/select-chat-sources-modal/contexts/selected-sources-context";

const cx = classNames.bind(styles);

export interface SelectTrainingSetButtonProps {
  trainingSet: TrainingSet;
	isSelected: boolean;
  totalSelectedMedia: number;
}

export const SelectTrainingSetButton = ({trainingSet, isSelected, totalSelectedMedia}: SelectTrainingSetButtonProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {setCurrentPage} = useNavigationContext();
	const {add, remove} = useSelectedSourcesContext();

	const handleToggleCheck = () => {
		if (isSelected) {
			remove([trainingSet]);
		} else {
			add([trainingSet]);
		}
	};

	return (
		<div
			className={cx(
				"selectTrainingSetButton",
				{
					selected: isSelected || totalSelectedMedia > 0,
					isDarkMode,
				}
			)}
			onClick={() => setCurrentPage(SelectSourcesPage.DATASET_MEDIA, trainingSet.id)}
		>
			<div className={styles.contentWrapper}>
				<div className={styles.nameWrapper}>
					<Checkbox
						checked={isSelected}
						onChange={handleToggleCheck}
						onClick={(e) => e.stopPropagation()}
					/>

					<OverflowTooltip>
						<Subheader
							color="text-body"
							type="semibold"
							className={styles.trainingSetName}
						>
							{trainingSet.alias}
						</Subheader>
					</OverflowTooltip>
				</div>

				<Body
					className={styles.trainingSetDescription}
					color="text-secondary"
					size="s"
				>
					{trainingSet.description}
				</Body>
			</div>

			<Separator className={styles.separator} />

			<div className={styles.filesSection}>
				<div className={styles.totalFiles}>
					<FileCorrectIcon className={styles.totalFilesIcon} />

					<Body
						size="s"
						type="medium"
						className={styles.totalFilesText}
					>
						{trainingSet.files.length + trainingSet.videos.length} Files
					</Body>
				</div>

				<Body
					size="s"
					type="medium"
					className={styles.selectedFiles}
				>
					Selected: <span className={styles.selectedFilesCount}>{totalSelectedMedia}</span>
				</Body>
			</div>
		</div>
	);
}
