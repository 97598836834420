import React, {ReactElement} from "react";

import {SearchInput} from "@/shared/v2";
import {useSearchContext} from "../../contexts/search-context";

import styles from "./search-sources-input.module.scss";

export const SearchSourcesInput = (): ReactElement => {
  const {search, setSearch} = useSearchContext();

  return (
    <SearchInput
      value={search}
      onChange={setSearch}
      className={styles.searchInput}
    />
  );
}
