/* eslint-disable react/prop-types */
import classNames from "classnames/bind";
import React, {ReactElement, useCallback, useMemo} from "react";

import {ArrowDownIcon, ArrowUpIcon} from "@/icons";
import {Checkbox, Caption, Body} from "@/shared/v2";
import {Column, IdType, useTable, useFlexLayout, useSortBy, usePagination, useRowSelect, useMountedLayoutEffect} from "react-table";
import {FileLabels} from "@/datasets/components/dataset-page/file-labels";
import {FileStatus} from "@/datasets/components/dataset-page/file-status";
import {OverflowTooltip} from "@/shared/v2/tooltip/overflow-tooltip";
import {TablePagination} from "@/shared/components/table/table-pagination";
import {TrainingSetMedia} from "@/models/ai-model";
import {useSelectedSourcesContext} from "../../contexts/selected-sources-context";
import {useThemeMode} from "@/context/theme-mode-context";

import styles from "./media-table.module.scss";

const bStyles = classNames.bind(styles);

export interface TableProps {
  media: TrainingSetMedia[];
}

const isMediaSelected = (media: TrainingSetMedia, selectedMediaArray: TrainingSetMedia[]): boolean => {
  return Boolean(selectedMediaArray.find(m => m.id === media.id));
}

const PAGE_SIZE = 100;

export const MediaTable = ({
	media,
}: TableProps): ReactElement => {
	const {
    selected: selectedSources,
    add: addSelectedMedia,
    remove: removeSelectedMedia,
  } = useSelectedSourcesContext();

	const {isDarkMode} = useThemeMode();

  const selectedMediaArray = useMemo(() => {
		const {
			trainingSets,
			media: {
				files,
				videos,
			}
		} = selectedSources;

		const selectedTrainingSetsMedia = trainingSets.map(({files, videos}) => [...files, ...videos]).flat();
    return [...files, ...videos, ...selectedTrainingSetsMedia];
  }, [selectedSources]);

  const isAllSelected = useMemo(() => {
    return media.every(media => isMediaSelected(media, selectedMediaArray));
  }, [selectedMediaArray]);

  const handleSelectOne = (media: TrainingSetMedia, isActive: boolean) => {
    if (isActive) {
      removeSelectedMedia([media]);
    } else {
      addSelectedMedia([media]);
    }
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      removeSelectedMedia(media);
    } else {
      addSelectedMedia(media);
    }
  };

  const columns = useMemo(
		(): Column<TrainingSetMedia>[] => [
			{
				Header: "Name",
				accessor: "originalFilename",
				Cell: data => {
					const file = data.row.original;
					return (
						<a href={file.url} target="_blank" className={styles.fileLink} rel="noreferrer">
							<OverflowTooltip>
								<Body size="s" type="medium" className={styles.overflow}>
									{file.originalFilename}
								</Body>
							</OverflowTooltip>
						</a>
					);
				},
			},
			{
				Header: "Type",
				accessor: "originalMimetype",
				Cell: data =>
					data.value && (
						<Body size="s" className={styles.overflow} color="text-tertiary">
							{data.value}
						</Body>
					),
			},
			{
				Header: "Status",
				accessor: "embeddingsGenerationStatus",
				Cell: data => {
					const row = data.row.original;

					return <FileStatus row={row} />;
				},
			},
			{
				Header: "Labels",
				Cell: data => {
					const row = data.row.original;
					const tags = row.tags;

					return (
            <FileLabels
              tags={tags}
              max={-1}
            />
          )
				}
			},
		],
		[selectedMediaArray, selectedSources, media]
	);

	const {
		getTableProps,
		headerGroups,
		page,
		prepareRow,
		setPageSize,
		selectedFlatRows,
		toggleAllRowsSelected,
		pageCount,
		state: {pageIndex, pageSize, selectedRowIds},
		gotoPage,
		previousPage,
		nextPage,
		rows,
	} = useTable(
		{
			columns,
			data: media,
			initialState: {
				pageSize: PAGE_SIZE,
        selectedRowIds: selectedMediaArray.reduce((acc, row) => {
          const index = media.findIndex(media => media.id === row.id);
          if (index !== -1) {
            acc[index] = true;
          };
          return acc;
        }, {} as Record<IdType<TrainingSetMedia>, boolean>),
			},
		},
		useFlexLayout,
		useSortBy,
		usePagination,
		useRowSelect,
		hooks => {
      hooks.visibleColumns.push(allColumns => [
        {
          id: "selection",
          width: 25,
          Header: ({getToggleAllPageRowsSelectedProps}) => {

            const {
              ...props
            } = getToggleAllPageRowsSelectedProps();

            return (
              <Checkbox
                {...props}
                checked={isAllSelected}
                className={styles.checkbox}
                onChange={handleSelectAll}
              />
            )
          },
          Cell: ({row}) => {
            const isSelected = isMediaSelected(row.original, selectedMediaArray);

            return (
              <Checkbox
                checked={isSelected}
                className={styles.checkbox}
                onChange={() => handleSelectOne(row.original, isSelected)}
              />
            )
          },
        },
        ...allColumns,
      ]);
		},
	);

	const countFrom = (pageIndex * pageSize) + 1;
	const countTo = (countFrom + pageSize - 1) > rows.length ?
		rows.length : (countFrom + pageSize) - 1;

	useMountedLayoutEffect(() => {
		if (selectedMediaArray?.length !== 0 && selectedFlatRows.length === 0) toggleAllRowsSelected(false);
	}, [selectedMediaArray]);

	const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    gotoPage(0); // Reset to the first page
  };

	return (
		<div className={styles.mainContainer}>
			<div className={styles.tableContainer}>
				<table {...getTableProps()} className={bStyles("table", {isDarkMode})}>
					<thead className={styles.header}>
						{headerGroups.map((headerGroup, i) => (
							<tr {...headerGroup.getHeaderGroupProps()} key={i}>
								{headerGroup.headers.map((column, j) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())} key={j}>
										<Caption type="medium" color="gray-modern-600" className={styles.tableThText}>
											{column.render("Header")}
											{column.isSorted ?

												column.isSortedDesc ?
												<ArrowDownIcon className={styles.arrowIcon} /> :
												<ArrowUpIcon className={styles.arrowIcon}/>
											: null}
											</Caption>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{page.map((row, i) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()} key={i}>
									{row.cells.map((cell, j) => (
										<td {...cell.getCellProps()} key={j}>
											{cell.render("Cell", {})}
										</td>
									))}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className={styles.footer}>
				<TablePagination
					currentPage={pageIndex}
					pageCount={pageCount}
					handleNext={nextPage}
					handlePrev={previousPage}
					gotoPage={gotoPage}
					startingRow={countFrom}
					endRow={countTo}
					totalCount={rows.length}
					pageSize={pageSize}
					onPageSizeChange={handlePageSizeChange}
				/>
			</div>
		</div>
	);
};
