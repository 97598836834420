import React, {ReactElement, ReactNode} from "react";

import {ActionsContextProvider} from "./actions-context";
import {NavigationContextProvider} from "./navigation-context";
import {SearchContextProvider} from "./search-context";
import {SelectedSourcesContextProvider} from "./selected-sources-context";

export interface SelectChatSourcesContextProvidersProps {
  onClose: () => void;
  children: ReactNode;
}

export const SelectChatSourcesContextProviders = ({onClose, children}: SelectChatSourcesContextProvidersProps): ReactElement => {
  return (
    <SearchContextProvider>
      <NavigationContextProvider close={onClose}>
        <SelectedSourcesContextProvider>
          <ActionsContextProvider>
            {children}
          </ActionsContextProvider>
        </SelectedSourcesContextProvider>
      </NavigationContextProvider>
    </SearchContextProvider>
  );
}
