import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {ClearAllButton, CancelButton, SubmitButton} from "./components/buttons";
import {Content} from "./components/content";
import {Modal} from "@/shared/v2";
import {SearchSourcesInput} from "./components/search-sources-input";
import {SelectChatSourcesContextProviders} from "./contexts";
import {SourcesNavigation} from "./components/sources-navigation";
import {useThemeMode} from "@/context/theme-mode-context";

import styles from "./select-chat-sources-modal.module.scss";

const cx = classNames.bind(styles);

export interface SelectSourceModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SelectChatSourcesModal = ({isOpen, onClose}: SelectSourceModalProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return (
		<Modal
			title="Choose Sources"
			isOpen={isOpen}
			onClose={onClose}
			className={styles.selectSourceModal}
			headerClassName={styles.header}
			portal
		>
			<SelectChatSourcesContextProviders onClose={onClose}>
				<div className={styles.navWrapper}>
					<SourcesNavigation />
					<SearchSourcesInput />
				</div>

				<div className={cx("content", {isDarkMode})}>
					<Content />
				</div>

				<div className={styles.bottomSection}>
					<ClearAllButton />

					<div className={styles.commitButtonsWrapper}>
						<CancelButton />
						<SubmitButton />
					</div>
				</div>
			</SelectChatSourcesContextProviders>
		</Modal>
	);
}
