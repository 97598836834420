import React, {ReactElement, ReactNode, createContext, useEffect, useMemo, useState} from "react";

import {ChatMedia, ChatMediaContextProvider, ChatMediaUpdate, useChatMediaContext} from "./chat-media-context";
import {TrainingSet} from "@/models";
import {TrainingSetFile, TrainingSetVideo} from "@/models/ai-model";
import {useChatConversationContext} from "./chat-conversation-context";
import {useSearchParams} from "react-router-dom";
import {ChatTrainingSetContextProvider, useChatTrainingSetContext} from "./chat-training-set-context";

export interface ChatSources {
  trainingSets: TrainingSet[];
  media: ChatMedia;
}

export interface ChatSourcesPartial {
  trainingSets?: TrainingSet[];
  media?: Partial<ChatMedia>;
}

export type ChatSourcesUpdate = Partial<{
  trainingSets: string[];
  media: ChatMediaUpdate;
}>;

export interface ChatSourcesContextValue {
  isLoading: boolean;
  isUpdatingDisabled: boolean;
  sources: ChatSources;
  active: ChatSources;
  normalize: (sources: ChatSources) => ChatSources;
  save: (sources: ChatSourcesUpdate) => void;
}

export const ChatSourcesContext =
  createContext<ChatSourcesContextValue | undefined>(undefined);

export interface ChatSourcesContextProviderProps {
  children: ReactNode;
}

const ChatSourcesContextComponent = (
  {children}: ChatSourcesContextProviderProps,
): ReactElement => {
  const [, setSearchParams] = useSearchParams();
  const {
    conversation,
    updateConversation,
    isLoading: isLoadingConversation,
  } = useChatConversationContext();
  const {
    trainingSets,
    activeTrainingSets,
    isLoading: isLoadingTrainingSets,
  } = useChatTrainingSetContext();
  const {
    media,
    activeMedia,
    isLoading: isLoadingMedia,
  } = useChatMediaContext();
  const [activeSources, setActiveSources] = useState<ChatSources>({trainingSets: [], media: {files: [], videos: []}});

  const isLoading = Boolean(isLoadingTrainingSets || isLoadingMedia);
  const isUpdatingDisabled = Boolean(isLoadingConversation || isLoading || isLoadingConversation);

  const sources = useMemo(() => {
    return {
      trainingSets,
      media,
    };
  }, [trainingSets, media]);

  useEffect(() => {
    setActiveSources(normalizeSources({
      trainingSets: activeTrainingSets || [],
      media: activeMedia,
    }));
  }, [
    sources,
    activeTrainingSets,
    activeMedia,
  ]);

  const saveSources = async (update: ChatSourcesUpdate) => {
    if (isUpdatingDisabled) {
      throw new Error("Sources updating is disabled");
    }

    const updatedActiveSources = applyUpdatesToActiveSources(sources, activeSources, update);

    const {
      trainingSets: newTrainingSetIds = [],
      media: {
        files: newFileIds = [],
        videos: newVideoIds = []
      } = {},
    } = convertChatSourcesToChatSourcesUpdate(updatedActiveSources);
    const currentActiveSources = {...activeSources};

    try {
      setActiveSources(updatedActiveSources);

      if (conversation) {
        await updateConversation({
          trainingSetIds: newTrainingSetIds,
          fileIds: newFileIds,
          videoIds: newVideoIds,
        });
      } else {
        setSearchParams(searchParams => {
          if (newTrainingSetIds.length === 0) {
            searchParams.delete("trainingSetIds");
          } else {
            searchParams.set("trainingSetIds", newTrainingSetIds.join(","));
          }

          if (newFileIds.length === 0) {
            searchParams.delete("fileIds");
          } else {
            searchParams.set("fileIds", newFileIds.join(","));
          }

          if (newVideoIds.length === 0) {
            searchParams.delete("videoIds");
          } else {
            searchParams.set("videoIds", newVideoIds.join(","));
          }

          return searchParams;
        }, {replace: true});
      }

    } catch (error) {
      setActiveSources(currentActiveSources);
      throw error;
    }
  };

  return (
    <ChatSourcesContext.Provider value={{
      isLoading,
      isUpdatingDisabled,
      sources,
      active: activeSources,
      normalize: normalizeSources,
      save: saveSources,
    }}>
      {children}
    </ChatSourcesContext.Provider>
  );
};

export const ChatSourcesContextProvider = (props: ChatSourcesContextProviderProps) => {
  return (
    <ChatTrainingSetContextProvider>
      <ChatMediaContextProvider>
        <ChatSourcesContextComponent {...props} />
      </ChatMediaContextProvider>
    </ChatTrainingSetContextProvider>
  )
};

export const useChatSourcesContext = (): ChatSourcesContextValue => {
  const context = React.useContext(ChatSourcesContext);

  if (context === undefined) {
    throw new Error(
      "useChatSourcesContext must be used within a ChatSourcesContextProvider",
    );
  }

  return context;
};

export function applyUpdatesToActiveSources(
  allSources: ChatSources,
  activeSources: ChatSources,
  update?: ChatSourcesUpdate
): ChatSources {
  let activeTrainingSetIds = new Set(activeSources.trainingSets.map(ts => ts.id));
  let activeFileIds = new Set(activeSources.media.files.map(f => f.id));
  let activeVideoIds = new Set(activeSources.media.videos.map(v => v.id));

  // Apply updates if provided
  if (update) {
    if (update.trainingSets !== undefined) {
      activeTrainingSetIds = new Set(update.trainingSets);
    }

    if (update.media?.files !== undefined) {
      activeFileIds = new Set(update.media.files);
    }

    if (update.media?.videos !== undefined) {
      activeVideoIds = new Set(update.media.videos);
    }
  }

  const newTrainingSets = allSources.trainingSets.filter(ts => activeTrainingSetIds.has(ts.id));

  const allFiles = allSources.trainingSets.flatMap(ts => ts.files);
  const allVideos = allSources.trainingSets.flatMap(ts => ts.videos);

  const newFiles = allFiles.filter(f => activeFileIds.has(f.id));
  const newVideos = allVideos.filter(v => activeVideoIds.has(v.id));

  return normalizeSources({
    trainingSets: newTrainingSets,
    media: {
      files: newFiles,
      videos: newVideos,
    },
  });
}

export function convertChatSourcesToChatSourcesUpdate(sources: ChatSources): ChatSourcesUpdate {
  return {
    trainingSets: sources.trainingSets.map(ts => ts.id),
    media: {
      files: sources.media.files.map(f => f.id),
      videos: sources.media.videos.map(v => v.id),
    },
  };
}

export function normalizeSources(activeSources: ChatSources): ChatSources {
  const activeTrainingSetIds = new Set(activeSources.trainingSets.map(ts => ts.id));

  // If a training set is selected, remove any of its media from active selections
  // Otherwise, leave the media as is.

  // 1. Identify which media should remain
  const filesToKeep: TrainingSetFile[] = [];
  const videosToKeep: TrainingSetVideo[] = [];

  // First, create a quick lookup for which sets are selected
  const selectedSets = new Set<string>(activeTrainingSetIds);

  // Only keep files that do not belong to a selected training set
  for (const file of activeSources.media.files) {
    if (!selectedSets.has(file.trainingSetId)) {
      filesToKeep.push(file);
    }
  }

  // Only keep videos that do not belong to a selected training set
  for (const video of activeSources.media.videos) {
    if (!selectedSets.has(video.trainingSetId)) {
      videosToKeep.push(video);
    }
  }

  // Construct the normalized result
  // - Keep training sets as is
  // - Keep only media not belonging to selected training sets
  return {
    trainingSets: activeSources.trainingSets,
    media: {
      files: filesToKeep,
      videos: videosToKeep,
    },
  };
}
