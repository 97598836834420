import React, {ReactElement, ReactNode, createContext} from "react";
import {useSearchContext} from "./search-context";

export enum SelectSourcesPage {
  DATASETS = "datasets",
  DATASET_MEDIA= "datasetMedia",
  MEDIA = "media",
}

export interface NavigationContextValue {
  currentPage: SelectSourcesPage;
  setCurrentPage: {
    (page: SelectSourcesPage.DATASETS | SelectSourcesPage.MEDIA): void;
    (page: SelectSourcesPage.DATASET_MEDIA, datasetId: string): void;
  }
  datasetId: string | null;
  close: () => void;
}

export const NavigationContext =
  createContext<NavigationContextValue | undefined>(undefined);

export const NavigationContextProvider = (
  {children, close}: {children: ReactNode, close: () => void},
): ReactElement => {
  const {setSearch} = useSearchContext();
  const [currentPage, setCurrentPage] = React.useState<SelectSourcesPage>(SelectSourcesPage.DATASETS);
  const [currentDatasetId, setCurrentDatasetId] = React.useState<string | null>(null);

  function handleSetCurrentPage(page: SelectSourcesPage.DATASETS | SelectSourcesPage.MEDIA): void;
  function handleSetCurrentPage(page: SelectSourcesPage.DATASET_MEDIA, datasetId: string): void;
  function handleSetCurrentPage(page: SelectSourcesPage, datasetId?: string): void {
    if (page === SelectSourcesPage.DATASET_MEDIA && datasetId) {
      setCurrentDatasetId(datasetId);
    } else {
      setCurrentDatasetId(null);
    }
    setSearch("");
    setCurrentPage(page);
  }

  return (
    <NavigationContext.Provider value={{
      currentPage,
      setCurrentPage: handleSetCurrentPage,
      datasetId: currentDatasetId,
      close
    }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = (): NavigationContextValue => {
  const context = React.useContext(NavigationContext);

  if (context === undefined) {
    throw new Error(
      "useNavigationContext must be used within a NavigationContextProvider",
    );
  }

  return context;
};
