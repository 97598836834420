/* eslint-disable */
import {formatDate, Options} from "../../../shared";
import React, {ReactElement, useContext, useEffect, useMemo, useState} from "react";
import {NavLink} from "../../../route";
import {GET_SEGMENTS} from "../../../graphql/queries/queries";
import {HeaderGroup, Row} from "react-table";
import {
	COMBINE_SEGMENTS,
	DELETE_SEGMENTS,
	DUPLICATE_SEGMENT,
	UPDATE_SEGMENT,
} from "../../../graphql/mutations/mutations";
import {ActionsButton} from "../../components/actions-button";
import {AlignText} from "../../../shared/typography/align-text";
import {AddListModal} from "../../../modals/add-list";
import {DeleteConfirmModal} from "../../../modals/delete-reel";
import {EmptyScreen} from "../../components/empty-screen";
import {
	CombineSegmentData,
	CombineSegmentsVars,
	DeleteSegmentsData,
	DuplicateSegmentData,
	SegmentData,
	SegmentSort,
	UpdateSegmentData,
	UpdateSegmentVars,
	WorkspaceSegment,
} from "../../../models/segment";
import {ToastContext} from "../../../context/toast-context";
import styles from "./lists.module.scss";
import {useLoadingQuery} from "../../../hooks";
import {
	updateCacheAddPageItem,
	updateCacheDeletePageItem,
} from "../../../shared/utility/update-cache";
import {NetworkStatus, useMutation} from "@apollo/client";
import {SEGMENT_FRAGMENT} from "../../../graphql/fragments/fragments";
import {Body} from "../../../shared/v2/typography";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {PaginatedTable} from "../../../shared/components/table/paginated-table";
import {Button, SearchInput} from "../../../shared/v2";

const PAGE_SIZE = 50;


const EditableCell = ({value: initialValue, row: {index, original}, column: {handleCancel, handleUpdate}, editableRowIndex}): JSX.Element => {
	const [value, setValue] = useState(initialValue);
	const handleEnter = (): void => handleUpdate(value, original.id);

	useEffect(() => setValue(initialValue), [initialValue]);

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (e.key === "Enter") handleEnter();
		if (e.key === "Escape") handleCancel();
	};
	return index === editableRowIndex ? (
		<input
			type="text"
			id="edit-list"
			className={styles.edit}
			value={value}
			onChange={e => setValue(e.target.value)}
			autoFocus
			onKeyDown={handleKeyPress}
		/>
	) : (
		<NavLink
			to={`/audience/lists/${original.id}`}
			state={{title: original.name, dynamicSegments: false}}
			className={styles.link}
		>{original.name}</NavLink>
	);
};

interface ListsPageProps {
	selector: JSX.Element;
}

const ListsPage = ({selector}: ListsPageProps): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const [showModal, setShowModal] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);
	const [searchValue, setSearchValue] = useState("");
	const [sortBy, setSortBy] = useState(SegmentSort.DEFAULT);
	const [editableRowIndex, setEditableRowIndex] = useState<number>();
	const [selectedLists, setSelectedLists] = useState<Row<WorkspaceSegment>[]>([]);
	const [list, setList] = useState("");
	const [showDelete, setShowDelete] = useState(false);
	const {updateToast, onError} = useContext(ToastContext);
	const [pageSize, setPageSize] = useState(PAGE_SIZE);

	const {data, fragment, networkStatus, fetchMoreFragment, handleFetchMore} = useLoadingQuery<SegmentData>(GET_SEGMENTS, {
		variables: {workspaceId, limit: pageSize, sort: sortBy, filter: {name: searchValue}},
		what: "lists",
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "cache-and-network",
	});

	const [deleteSegments] = useMutation<DeleteSegmentsData>(DELETE_SEGMENTS);
	const [copySegment] = useMutation<DuplicateSegmentData>(DUPLICATE_SEGMENT);
	const [combineSegments] = useMutation<CombineSegmentData, CombineSegmentsVars>(COMBINE_SEGMENTS);
	const [updateSegment] = useMutation<UpdateSegmentData, UpdateSegmentVars>(UPDATE_SEGMENT, {
		onCompleted: () => updateToast({description: "Updated List", type: "informational"}),
	});

	const handleDeleteList = (): void => {
		deleteSegments({
			variables: {ids: list},
			onCompleted: () => {
				setList("");
				updateToast({description: "Deleted List", type: "informational"});
			},
			onError: e => {
				setList("");
				onError(e);
			},
			update(cache, {data: deleteData}) {
				if (deleteData) {
					deleteData.deleteSegments.forEach(segment => {
						updateCacheDeletePageItem(
							cache,
							"segments",
							"segment",
							segment.id,
						);
					});
				}
			},
		});
	};

	const handleCopyList = (id: string): void => {
		copySegment({
			variables: {id},
			onCompleted: () => updateToast({description: "List duplicated", type: "informational"}),
			onError: (error) => {
				if (error.message.includes("UniqueViolationError")) {
					const originalList = data?.segments.items.find(item => item.id === id);
					updateToast({
						description: `Cannot duplicate "${originalList?.name}" - a list named "${originalList?.name} (copy)" already exists. Please rename the existing copy first.`,
						type: "failure"
					});
				} else {
					onError(error);
				}
			},
			update(cache, {data: segment}) {
				if (!segment) return;

				// Read the current query data
				const queryData = cache.readQuery<SegmentData>({
					query: GET_SEGMENTS,
					variables: {
						workspaceId,
						limit: PAGE_SIZE,
						sort: sortBy,
						filter: {name: searchValue}
					}
				});

				if (!queryData) return;

				// Write the updated data back to the cache
				cache.writeQuery<SegmentData>({
					query: GET_SEGMENTS,
					variables: {
						workspaceId,
						limit: PAGE_SIZE,
						sort: sortBy,
						filter: {name: searchValue}
					},
					data: {
						segments: {
							...queryData.segments,
							items: [segment.duplicateSegment, ...queryData.segments.items]
						}
					}
				});
			},
		});
	};

	const handleDeleteAll = (): void => {
		deleteSegments({
			variables: {ids: selectedLists?.map(r => r.original.id)},
			onCompleted: () => {
				setShowDelete(false);
				updateToast({description: "Deleted Lists", type: "informational"});
			},
			onError: e => {
				setShowDelete(false);
				onError(e);
			},
			update(cache, {data: deleteData}) {
				if (!deleteData) return;
				deleteData.deleteSegments.forEach(segment => {
					updateCacheDeletePageItem(
						cache,
						"segments",
						"segment",
						segment.id,
					);
				});
			},
		});
	};

	const handleCancel = (): void => setEditableRowIndex(undefined);
	const handleUpdate = (name: string, id: string): void => {
		setEditableRowIndex(undefined);
		updateSegment({variables: {id, changes: {name}}});
	};

	const currentData = useMemo(() =>
		(data?.segments.items.slice(currentPage * pageSize, (currentPage + 1) * pageSize)),
	[data, currentPage]);
	const columns = useMemo((): any => (
		[
			{
				Header: "Name",
				accessor: "name",
				minWidth: 200,
				handleCancel,
				handleUpdate,
				Cell: props => <EditableCell {...props}/>,
			},
			{
				Header: "User count",
				accessor: "memberCount",
				Cell: ({value}) => <Body size="s">{value}</Body>,
			},
			{
				Header: "Created",
				accessor: "createdAt",
				Cell: ({value}) => <Body size="s">{value ? formatDate(value) : "Unknown"}</Body>,
			},
			{
				id: "options",
				Header: " ",
				maxWidth: 50,
				disableSortBy: true,
				Cell: ({row: {original, id}}) => <AlignText align="right">
					<Options type="menu-vertical" position="right"
						options={[{
							name: "Edit",
							actionOptions: {onClick: () => setEditableRowIndex(Number(id))},
							icon: "pen",
							iconFill: "var(--color-text-body)",
						},
						{
							name: "Duplicate",
							actionOptions: {onClick: () => handleCopyList(original.id)},
							icon: "copy",
							iconFill: "var(--color-text-body)",
						},
						{
							name: "Delete",
							actionOptions: {onClick: () => setList(original.id)},
							icon: "trash",
							iconFill: "var(--color-text-body)",
						}]}
					/>
				</AlignText>,
			},
		]), [data]);

	const handleSort = (value: HeaderGroup<any>): void => {
		if (!value.canSort) return;

		switch (value.Header) {
			case "Name":
				if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(SegmentSort.NAME_ASC);
				if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(SegmentSort.NAME_DESC);
				setSortBy(SegmentSort.DEFAULT);
				break;
			case  "User count":
				if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(SegmentSort.MEMBER_COUNT_ASC);
				if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(SegmentSort.MEMBER_COUNT_DESC);
				setSortBy(SegmentSort.DEFAULT);
				break;
			case "Created":
				if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(SegmentSort.CREATED_AT_ASC);
				if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(SegmentSort.CREATED_AT_DESC);
				setSortBy(SegmentSort.DEFAULT);
				break;
			default:
				setSortBy(SegmentSort.DEFAULT);
		}

	};

	const handleCombine = (): void => {
		const segmentIds = selectedLists.map(row => row.original.id);
		combineSegments({
			variables: {ids: segmentIds},
			onCompleted: () => updateToast({description: "New combined list created", type: "informational"}),
			onError: () => updateToast({description: "Failed to create new list", type: "failure"}),
			update(cache, {data: combineData}) {
				if (!combineData) return;

				// Read the current query data
				const queryData = cache.readQuery<SegmentData>({
					query: GET_SEGMENTS,
					variables: {
						workspaceId,
						limit: PAGE_SIZE,
						sort: sortBy,
						filter: {name: searchValue}
					}
				});

				if (!queryData) return;

				// Write the updated data back to the cache
				cache.writeQuery<SegmentData>({
					query: GET_SEGMENTS,
					variables: {
						workspaceId,
						limit: PAGE_SIZE,
						sort: sortBy,
						filter: {name: searchValue}
					},
					data: {
						segments: {
							...queryData.segments,
							items: [combineData.combineSegments, ...queryData.segments.items]
						}
					}
				});
			},
		});
	};

	const toggleAddModal = (): void => setShowModal(prev => !prev);

	const handlePerPageChange = (newPageSize: number): void => {
		setPageSize(newPageSize);
		setCurrentPage(0);
	}

	return <>
		<header className={styles.head}>
			<div className={styles.inputs}>
				<SearchInput
					value={searchValue}
					onChange={setSearchValue}
				/>
				{selector}
			</div>
			{data && data.segments.items.length > 0 &&
			<div className={styles.actions}>
				{
					selectedLists.length > 0 &&
				<ActionsButton
					text={`Bulk actions (${selectedLists.length})`}
					options={[{
						name: "Delete",
						actionOptions: {onClick: () => setShowDelete(true)},
					},
					{
						name: "Combine Lists",
						actionOptions: {onClick: handleCombine}
					},
				]}
				/>
				}
				<Button onClick={toggleAddModal}>New List</Button>
			</div>
			}
		</header>
		{
			!data && fragment || ((currentData && data) && data.segments.items.length > 0 ? <PaginatedTable<any>
				columns={columns}
				data={currentData}
				pageSize={pageSize}
				totalCount={data.segments.items.length + data.segments.remaining}
				editableRowIndex={editableRowIndex}
				onSelectChange={setSelectedLists}
				selectedValues={selectedLists}
				dataLength={data.segments.items.length}
				handleFetchMore={handleFetchMore}
				fetchMoreFragment={fetchMoreFragment}
				pageState={[currentPage, setCurrentPage]}
				sortLoading={networkStatus === NetworkStatus.setVariables}
				onSort={handleSort}
				onPageSizeChange={handlePerPageChange}
			/> : searchValue ? <Body>No results found</Body>
				: <EmptyScreen whatIsEmpty="LIST" action={toggleAddModal}/>)
		}
		<AddListModal isOpen={showModal} onClose={toggleAddModal}/>
		<DeleteConfirmModal
			isOpen={Boolean(list)}
			onClose={() => setList("")}
			handleConfirm={handleDeleteList}
			warningText="Are you sure you want to delete this list?"
		/>
		<DeleteConfirmModal
			isOpen={showDelete}
			onClose={() => setShowDelete(false)}
			handleConfirm={handleDeleteAll}
			warningText="Are you sure you want to delete these lists?"
		/>
	</>;
};

export {ListsPage};
