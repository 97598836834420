import classNames from "classnames/bind";
import React, {ReactElement, useState} from "react";

import {CanvasPageState} from "../../../../../canvas";
import {ActionsDropdown} from "../../../../../canvas/chat-bubble/actions-dropdown";
import CommandTextArea from "../../../../../canvas/chat-bubble/command-text-area";
import {SourcesSection} from "../../../../../canvas/chat-bubble/sources-section";
import {UploadedImageSection} from "../../../../../canvas/chat-bubble/uploaded-image-section";
import {ChatMentionsContextProvider, useChatCampaignContext, useChatConversationContext, useChatImageContext, useChatSourcesContext} from "../../../../../context/chat-contexts";
import {KeyboardEventMiddlewareContextProvider} from "../../../../../context/keyboard-event-middleware-context";
import {useThemeMode} from "../../../../../context/theme-mode-context";
import {useTask} from "../../../../../hooks/useTask";
import {PlusIcon, SendArrowChatIcon} from "../../../../../icons";
import {Persona} from "../../../../../models";
import {useNavigate} from "../../../../../route";
import {CHAT_COMMANDS} from "../../../../../shared/constants/constants";
import {ButtonIcon} from "../../../../../shared/v2";

import styles from "./drawer-chat-bubble.module.scss";

const cx = classNames.bind(styles);

export interface DrawerChatBubbleProps {
	agent: Persona;
}

export const DrawerChatBubble = ({agent}: DrawerChatBubbleProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const navigate = useNavigate();
	const {createConversation} = useChatConversationContext();
	const {getImageBase64} = useChatImageContext();
	const [value, setValue] = useState<string>("");
	const isDisabled = value.trim() === "";

	const {run: handleResponse, loading: isRunningHandleResponse} = useTask(async () => {
		if (isDisabled) {
			return;
		}

		const {id: conversationId} = await createConversation({aiPersonaId: agent.id});

		navigate(`/workflow/conversation/?canvasId=${conversationId}`, {
			state: {
				invoke: {
					message: value,
					image: await getImageBase64() || undefined,
					persona: agent,
				} as CanvasPageState,
			}
		})
	})

	return (
		<div className={cx("chatBubble", {isDarkMode, disabled: isRunningHandleResponse})}>
			<div className={styles.bottomLine}>
				<ActionsDropdown agent={false}>
					<ButtonIcon
						icon={<PlusIcon />}
						className={styles.icon}
						aria-label='plus-button'
					/>
				</ActionsDropdown>

				<div className={styles.chatContentWrapper}>
					<div className={styles.chatAndSourcesWrapper}>
						<UploadedImageSection />

						<KeyboardEventMiddlewareContextProvider>
							<ChatMentionsContextProvider>
								<CommandTextArea
									value={value}
									onChange={setValue}
									placeholder={'Ask anything...'}
									commands={CHAT_COMMANDS}
									handleSubmit={handleResponse}
								/>
							</ChatMentionsContextProvider>
						</KeyboardEventMiddlewareContextProvider>
					</div>

					<SourcesSection />
				</div>

				<div className={cx("submitButton", {isDarkMode})}>
					<div
						className={styles.chatSubmit}
					>
						<ButtonIcon
							className={styles.icon}
							icon={<SendArrowChatIcon />}
							onClick={handleResponse}
							disabled={isDisabled}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

