import React, {ReactElement, useMemo} from "react";
import classNames from "classnames/bind";

import {EyePreviewIcon, FileTextIcon, HandOutputStarIcon} from "../../../icons";
import {Body, Button} from "../../../shared/v2";
import {AssistiveChip} from "../../../shared/v2/assistive-chip";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./flow-output-card.module.scss";
import {useWorkflowContext, useWorkflowEventsContext} from "../../../context/workflow-contexts";
import {NoDraggableItem} from "../no-draggable-item";

const cx = classNames.bind(styles);

export interface FlowOutputCardProps {
	reportUrl?: string;
	reportWordUrl?: string;
}

export const FlowOutputCard = ({reportUrl, reportWordUrl}: FlowOutputCardProps): ReactElement | null => {
	const {isDarkMode} = useThemeMode();
	const {isGeneratingReport} = useWorkflowEventsContext();
	const {isOnHistoryTab} = useWorkflowContext();

	const getFileName = (url?: string) => {
		if (!url) return "";
		const parts = url.split("/");
		return parts[parts.length - 1];
	};

	const reportName = useMemo(() => getFileName(reportUrl), [reportUrl]);
	const wordReportName = useMemo(() => getFileName(reportWordUrl), [reportWordUrl]);

	const renderContent = () => {
		if (!reportUrl && !reportWordUrl) {
			return null;
		}
		return <>
			<div className={styles.content}>
				{reportUrl && (
					<AssistiveChip
						className={styles.outputChip}
						text={`PDF: ${reportName}`}
						icon={<FileTextIcon />}
					/>
				)}
				{reportWordUrl && (
					<AssistiveChip
						className={styles.outputChip}
						text={`Word: ${wordReportName}`}
						icon={<FileTextIcon />}
					/>
				)}
			</div>

			<div className={styles.actions}>
				{reportUrl && (
					<Button
						leftIcon={<EyePreviewIcon />}
						variant="outlined"
						onClick={() => {
							window.open(reportUrl, "_blank");
						}}
						size="small"
					>
						View PDF
					</Button>
				)}
				{reportWordUrl && (
					<Button
						leftIcon={<FileTextIcon />}
						variant="outlined"
						onClick={() => {
							window.open(reportWordUrl, "_blank");
						}}
						size="small"
						className={styles.wordButton}
					>
						Open in Word
					</Button>
				)}
			</div>
		</>
	}


  return <NoDraggableItem className={styles.noDraggableWrapper}>
		<div className={cx("container", {processing: isGeneratingReport && !isOnHistoryTab, isDarkMode})}>
			<div className={styles.headerWrapper}>
				<HandOutputStarIcon className={styles.headerIcon} />
				<Body type="medium">Output</Body>
			</div>

			{renderContent()}
		</div>
	</NoDraggableItem>
}
