import {CssSpinner, Icon} from "../../../shared";
import {QuestionArModel, QuestionImage, SurveyVideo} from "../../../models/questions";
import React, {ReactElement, useEffect, useState} from "react";
import styles from "./question-media.module.scss";
import {Button} from "../../../shared/v2";
import {PhotoImageIcon, Square3dIcon} from "../../../icons";

export interface QuestionMediaProps {
	image?: SurveyVideo | QuestionImage | QuestionArModel;
	deleteImage: () => void;
	setShowUploadModal: (s: boolean) => void;
	setShowUploadARModal: (s: boolean) => void;
	isDeleting: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isArModel = (m: any): m is QuestionArModel => m.usdzUrl !== undefined;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isVideo = (m: any): m is SurveyVideo => m.video || m.mp4;

const QuestionMedia = (props: QuestionMediaProps): ReactElement => {
	const {image, deleteImage, setShowUploadModal, setShowUploadARModal, isDeleting} = props;
	const [showVideo, setShowVideo] = useState<boolean>(false);

	const handleShowModal = (): void => setShowUploadModal(true);
	const handleShowARModal = (): void => setShowUploadARModal(true);

	const changeToVideo = (): void => setShowVideo(true);

	const handleDelete = (): void => {
		setShowVideo(false);
		deleteImage();
	};

	useEffect(() => {
		if (showVideo) setShowVideo(false);
	}, [image]);

	const getDisplay = (): ReactElement => {
		if (!image) return <></>;
		return <>
			{isArModel(image) ? (
				<model-viewer
					style={{width: 160, height: 120}}
					src={image.glbUrl}
					ios-src={image.usdzUrl}
					alt="A 3D model"
					auto-rotate
					camera-controls
					background-color="#ffffff"
					ar
					magic-leap
					unstable-webxr
				/>
			) : isVideo(image) && showVideo ? (
				<video className={styles.video} controls autoPlay>
					<source src={image.mp4} type="video/mp4" />
					<source src={image.video} type="video/webm" />
				</video>
			) : (
				<div className={styles.playIcon}>
					<img className={styles.image} src={image.small} />
					{isVideo(image) && <div onClick={changeToVideo} className={styles.play}>
						<Icon name="play-solid" size="small" fill="white"/>
					</div>}
				</div>
			)}
			<Icon
				name="circle-plus"
				size="extrasmall"
				fill="var(--batterii-grey-medium)"
				className={styles.cross}
				clicked={handleDelete}
			/>
		</>;
	};

	return (
		<div className={styles.container}>
			{getDisplay()}
			{isDeleting && <div className={styles.deleting}>
				<CssSpinner />
			</div>}
			{!image && <>
				<Button
					variant="outlined"
					onClick={handleShowModal}
					size="small"
					leftIcon={<PhotoImageIcon />}
				>
					Add image/video
				</Button>
				<Button
					variant="outlined"
					onClick={handleShowARModal}
					size="small"
					leftIcon={<Square3dIcon />}
				>
					Add 3D/AR (Beta)
				</Button>
			</>}
		</div>
	);
};

export {QuestionMedia};
