import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import styles from "./nav-item.module.scss";
import {NavLink} from "../../../../route";
import {Body} from "../../../../shared/v2/typography";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {Badge} from "../../../../workspace-settings/components/model-card/badge";

const bStyles = classNames.bind(styles);

export interface NavItemProps {
	to: string;
	icon: ReactElement;
	text: string;
	isBeta?: boolean;
}

export const NavItem = ({
	to,
	icon,
	text,
	isBeta,
}: NavItemProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return (
		<NavLink
			className={bStyles("navItem", {isDarkMode})}
			to={to}
			workspace
			omitSearch
			activeClassName={styles.active}
		>
			<div className={styles.iconWrapper}>{icon}</div>
			<div className={styles.textWrapper}>
				<Body className={styles.text} size="s" type="semibold" color="text-secondary">{text}</Body>
				{isBeta && (
                    <Badge text="beta" tooltip="This feature is in beta and may not work perfectly yet. We’d love your feedback to help us refine and improve it!"/>
				)}
			</div>
		</NavLink>
	);
};
