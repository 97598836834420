import React, { ReactElement, useMemo } from 'react';
import { ButtonIcon } from '../buttons';
import { ChevronLeftIcon, ChevronRightIcon } from '../../../icons';
import classNames from 'classnames/bind';

import styles from './pagination.module.scss';
import {Body} from '../typography';
import {useThemeMode} from '@/context/theme-mode-context';
import { SelectInput } from '@/shared/v2';

const bSylyles = classNames.bind(styles);

interface PaginationProps {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    onNextPage: () => void;
    onPreviousPage: () => void;
    totalCount: number;
    currentCount: number;
    className?: string;
    currentPage?: number;
    totalPages?: number;
    onPageChange?: (page: number) => void;
    itemsPerPage: number;
    onItemsPerPageChange: (itemsPerPage: number) => void;
}

export const Pagination = ({
    hasNextPage,
    hasPreviousPage,
    onNextPage,
    onPreviousPage,
    totalCount,
    currentCount,
    className,
    currentPage = 1,
    totalPages = 1,
    onPageChange,
    itemsPerPage = 10,
    onItemsPerPageChange,
}: PaginationProps): ReactElement => {
    const {isDarkMode} = useThemeMode();

    const renderPageNumbers = () => {
        const pages: ReactElement[] = [];
        const maxVisiblePages = 5;
        let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        let endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        if (endPage - startPage + 1 < maxVisiblePages) {
            startPage = Math.max(1, endPage - maxVisiblePages + 1);
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button
                    key={i}
                    className={`${styles.pageButton} ${i === currentPage ? styles.active : ''}`}
                    onClick={() => onPageChange?.(i)}
                >
                    <Body key={`page-text-${i}`} className={styles.pageItem} size="s">{i}</Body>
                </button>
            );
        }

        if (startPage > 1) {
            pages.unshift(
                <Body key="start-ellipsis" size="s" className={styles.ellipsis}>
                    <span key="start-dots">...</span>
                </Body>
            );
        }

        if (endPage < totalPages) {
            pages.push(
                <Body key="end-ellipsis" size="s" className={styles.ellipsis}>
                    <span key="end-dots">...</span>
                </Body>
            );
        }

        return pages;
    };

    const showOptions = useMemo(() => {
        return [
            {
                id: "100",
                name: "100",
            },
            {
                id: "50",
                name: "50",
            },
            {
                id: "20",
                name: "20",
            },
            {
                id: "10",
                name: "10",
            },
        ];
    }, [])

    const handlePerPageChange = (value: string) => {
        onItemsPerPageChange(parseInt(value));
    }

    return (
        <div className={bSylyles("container", className, {isDarkMode})}>
            <div className={styles.info}>
                <Body size="s" color='text-secondary'>
                    Showing {currentCount > 0 && `${(currentPage - 1) * currentCount + 1} to ${Math.min(currentPage * currentCount, totalCount)} of ${totalCount}`}
                </Body>
            </div>
            <div className={styles.controls}>
                <ButtonIcon
                    icon={<ChevronLeftIcon className={styles.chevronIcon} />}
                    onClick={onPreviousPage}
                    disabled={!hasPreviousPage}
                    title="Previous page"
                />
                <div className={styles.pageNumbers}>
                    {renderPageNumbers()}
                </div>
                <ButtonIcon
                    icon={<ChevronRightIcon className={styles.chevronIcon} />}
                    onClick={onNextPage}
                    disabled={!hasNextPage}
                    title="Next page"
                />
            </div>

            <div className={styles.rowsWrapper}>
                <Body size='s' color='text-secondary'>Show</Body>
                <SelectInput
                    className={styles.showDropdown}
                    options={showOptions}
                    value={itemsPerPage.toString()}
                    onChange={(value) => handlePerPageChange(value)}
                />
            </div>
        </div>
    );
};
