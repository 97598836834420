import {useNavigate} from "react-router";
import {useMutation, useQuery} from "@apollo/client";
import React, {ReactElement, useEffect, useState, useCallback} from "react";
import debounce from 'lodash/debounce';

import {AiOrchestrationDeleteData, AiOrchestrationsData} from "../../../models/ai-orchestration";
import {Button, LoadingContainer, SearchInput} from "../../../shared/v2";
import {PlusSmallIcon} from "../../../icons";
import {DELETE_AI_ORCHESTRATION} from "../../../graphql/mutations/ai-mutations";
import {GET_AI_ORCHESTRATIONS} from "../../../graphql/queries/ai-orchestration-queries";
import {DeleteConfirmModal} from "../../../modals/delete-reel";
import {WorkflowCard} from "../../components/workflow-card";
import {WorkflowSort} from "../../components/workflow-sort";
import {Pagination} from "../../../shared/v2/pagination";
import {useToastContext} from "../../../context/toast-context";
import {useUserContext} from "../../../context/user-context";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {WorkflowEventsProvider} from "../../../context/workflow-contexts/workflow-events-context";
import {CreateWorkflowModal} from "../../modals/create-workflow-modal";
import {CreateCard} from "../../../shared/v2/cards";
import {FlowsDisabledState} from "./flows-disabled-state";

import styles from "./flows-page.module.scss";

const ITEMS_PER_PAGE = 10;
const SEARCH_DEBOUNCE_MS = 300;

const FlowsPage = (): ReactElement => {
	const {workspace} = useWorkspaceContext();
	const {user: {role}} = useUserContext();
	const {updateToast} = useToastContext();
	const navigate = useNavigate();

	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [idForDelete, setIdForDelete] = useState("");
	const [searchValue, setSearchValue] = useState<string>("");
	const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("");
	const [sortValue, setSortValue] = useState("DEFAULT");
	const [currentPage, setCurrentPage] = useState(1);
	const [isLoadingPage, setIsLoadingPage] = useState(false);
	const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE);

	// Store cursors for each page
	const [cursors, setCursors] = useState<{ [key: number]: string }>({});

	// Keep track of the last successful data for smooth transitions
	const [lastSuccessfulData, setLastSuccessfulData] = useState<{
		workflows: any[];
		totalCount: number;
		hasNextPage: boolean;
	} | null>(null);

	// Debounced search handler
	const debouncedSetSearch = useCallback(
		debounce((value: string) => {
			setDebouncedSearchValue(value);
			setCursors({});
			setCurrentPage(1);
		}, SEARCH_DEBOUNCE_MS),
		[]
	);

	// Update search value immediately for UI, but debounce the actual search
	const handleSearch = (value: string) => {
		setSearchValue(value);
		debouncedSetSearch(value);
	};

	const {data, loading, fetchMore, refetch} = useQuery<AiOrchestrationsData>(GET_AI_ORCHESTRATIONS, {
		variables: {
			workspaceId: workspace.id,
			input: {
				first: itemsPerPage,
				sort: sortValue,
				after: cursors[currentPage - 1],
				search: debouncedSearchValue || undefined
			}
		},
		fetchPolicy: "cache-and-network",
		onCompleted: (data) => {
			if (data?.aiOrchestrations) {
				const totalCount = data.aiOrchestrations.totalCount || 0;
				const currentCount = data.aiOrchestrations.edges?.length || 0;
				const hasNextPage = data.aiOrchestrations.pageInfo.hasNextPage &&
					(currentPage * itemsPerPage) < totalCount;

				setLastSuccessfulData({
					workflows: data.aiOrchestrations.edges?.map(edge => edge.node) || [],
					totalCount,
					hasNextPage
				});
				setIsLoadingPage(false);
			}
		}
	});

	const [deleteOrchestration] = useMutation<AiOrchestrationDeleteData>(DELETE_AI_ORCHESTRATION);

	const currentData = loading && lastSuccessfulData ? lastSuccessfulData : {
		workflows: data?.aiOrchestrations?.edges?.map(edge => edge.node) || [],
		totalCount: data?.aiOrchestrations?.totalCount || 0,
		hasNextPage: data?.aiOrchestrations?.pageInfo.hasNextPage &&
			(currentPage * itemsPerPage) < (data?.aiOrchestrations?.totalCount || 0)
	};

	const totalPages = Math.ceil(currentData.totalCount / itemsPerPage);

	const openWorkflowPage = (id: string): void => {
		navigate(`/workflow/flows/${id}`);
	}

	const handleDeleteWorkflow = (): void => {
		deleteOrchestration({
			variables: {id: idForDelete},
			onCompleted: (data) => {
				updateToast({description: "Deleted Workflow", type: "informational"});
				setIdForDelete("");
				refetch();
			},
			onError: () => {
				setIdForDelete("");
                refetch();
			},
			update(cache, {data}) {
				if (!data?.deleteAiOrchestration) return;
				cache.modify({
					fields: {
						aiOrchestrations(existing = [], {readField}) {
							const copied = [...existing];
							const updatedOrchestras = copied.filter(
								ref => data.deleteAiOrchestration.id !== readField("id", ref),
							);
							return updatedOrchestras;
						},
					},
				});
				cache.evict({id: `AiOrchestra:${data.deleteAiOrchestration.id}`, broadcast: false});
			},
		});
	};

	const handleAddWorkflow = (id: string): void => {
		setIsCreateModalOpen(false);
		openWorkflowPage(id);
	};

	const loadPageData = async (targetPage: number) => {
		if (targetPage === 1) {
			// For first page, just reset everything
			setCursors({});
			return;
		}

		setIsLoadingPage(true);

		try {
			// We need to load all pages up to the target page to maintain the cursor chain
			for (let i = Object.keys(cursors).length + 1; i < targetPage; i++) {
				const result = await fetchMore({
					variables: {
						workspaceId: workspace.id,
						input: {
							first: itemsPerPage,
							sort: sortValue,
							after: cursors[i - 1],
							search: debouncedSearchValue || undefined
						}
					}
				});

				const endCursor = result.data.aiOrchestrations.pageInfo.endCursor;
				if (endCursor) {
					setCursors(prev => ({ ...prev, [i]: endCursor }));
				}
			}
		} catch (error) {
			console.error('Error loading page data:', error);
			setIsLoadingPage(false);
		}
	};

	const handleNextPage = () => {
		if (!currentData.hasNextPage) return;

		const endCursor = data?.aiOrchestrations?.pageInfo?.endCursor;
		if (endCursor) {
			setCursors(prev => ({ ...prev, [currentPage]: endCursor }));
			setCurrentPage(prev => prev + 1);
		}
	};

	const handlePreviousPage = () => {
		if (currentPage > 1) {
			setCurrentPage(prev => prev - 1);
		}
	};

	const handlePageChange = (page: number) => {
		if (page === currentPage) return;
		setCurrentPage(page); // Update the page immediately for UI responsiveness
		loadPageData(page); // Load the data in the background
	};

	const handleSort = (value: string) => {
		setSortValue(value);
		setCursors({});
		setCurrentPage(1);
	};

	const renderContent = (): ReactElement => {
		const isLoading = loading || isLoadingPage;

		return (
			<>
				<div className={styles.cardsContainer}>
					<div className={styles.cards}>
						<CreateCard wrapperClassname={styles.cardWrapper}>
							<Button
								leftIcon={<PlusSmallIcon className={styles.plusIcon} />}
								color="purple"
								onClick={() => setIsCreateModalOpen(true)}
							>
								Create new
							</Button>
						</CreateCard>
						{currentData.workflows.map(workflow => (
							<WorkflowCard
								key={workflow.id}
								workflow={workflow}
								onClick={openWorkflowPage}
								handleDelete={id => setIdForDelete(id)}
							/>
						))}
					</div>
					{isLoading && (
						<div className={styles.loadingOverlay}>
							<LoadingContainer />
						</div>
					)}
				</div>
				<Pagination
					hasNextPage={currentData.hasNextPage || false}
					hasPreviousPage={currentPage > 1}
					onNextPage={handleNextPage}
					onPreviousPage={handlePreviousPage}
					totalCount={currentData.totalCount}
					currentCount={currentData.workflows.length}
					className={styles.pagination}
					currentPage={currentPage}
					totalPages={totalPages}
					onPageChange={handlePageChange}
					itemsPerPage={itemsPerPage}
					onItemsPerPageChange={setItemsPerPage}
				/>
			</>
		);
	}

	if (!workspace.workflowEnabled) {
		return <FlowsDisabledState />
	}

	return(
		<WorkflowEventsProvider>
			<div className={styles.container}>
			<div className={styles.header}>
				<SearchInput
					placeholder="Name or description"
					className={styles.searchInput}
					value={searchValue}
					onChange={handleSearch}
				/>
				<WorkflowSort
					value={sortValue}
					onChange={handleSort}
					className={styles.sort}
				/>
			</div>
			{renderContent()}

			<DeleteConfirmModal
				isOpen={Boolean(idForDelete)}
				onClose={() => setIdForDelete("")}
				handleConfirm={handleDeleteWorkflow}
				warningText="Are you sure you want to delete this flow?"
			/>

				<CreateWorkflowModal
					isOpen={isCreateModalOpen}
					onClose={() => setIsCreateModalOpen(false)}
					onAdd={handleAddWorkflow}
				/>
			</div>
		</WorkflowEventsProvider>
	);
};

export {FlowsPage};
