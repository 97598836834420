import React, {ReactElement, useMemo} from "react";

import {MediaTable} from "../../media-table";
import {SelectSourcesPage, useNavigationContext} from "@/canvas/select-chat-sources-modal/contexts/navigation-context";
import {useChatSourcesContext} from "@/context/chat-contexts";
import {ButtonIcon, Subheader} from "@/shared/v2";
import {ChevronLeftIcon} from "@/icons";
import {OverflowTooltip} from "@/shared/v2/tooltip/overflow-tooltip";

import styles from "./select-dataset-media-tab.module.scss";

export const SelectDatasetMediaTab = (): ReactElement => {
  const {sources: {trainingSets}} = useChatSourcesContext();
  const {datasetId, setCurrentPage} = useNavigationContext();

  const trainingSet = useMemo(() => {
    const result = trainingSets.find(({id}) => id === datasetId);

    if (!result) {
      throw new Error(`Training set not found: ${datasetId}`);
    }

    return result;
  }, [datasetId, trainingSets]);

  const media = useMemo(() => {
    return [...trainingSet.files, ...trainingSet.videos];
  }, [trainingSet]);

  return (
    <div className={styles.selectDatasetMediaTab}>
      <div className={styles.controls}>
        <ButtonIcon
          icon={<ChevronLeftIcon />}
          color="text-secondary"
          onClick={() => setCurrentPage(SelectSourcesPage.DATASETS)}
        />

        <OverflowTooltip>
          <Subheader
            type="semibold"
            color="text-body"
            size="l"
            className={styles.trainingSetName}
          >
            {trainingSet.alias}
          </Subheader>
        </OverflowTooltip>
      </div>

      <MediaTable media={media} />
    </div>
  );
}
