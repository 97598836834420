import React, {ReactElement, useState} from "react";
import {BaseModalProps, Body, Button, Input, Modal, TextArea, Checkbox} from "../../../shared/v2";
import {useMutation} from "@apollo/client";

import {DocumentsFolderFileIcon, HornMegaphoneCampaignIcon, PlusSmallIcon} from "../../../icons";
import {useValidation} from "../../../hooks/useValidation";
import {CreateAiOrchestrationData, CreateAiOrchestrationVars} from "../../../models/ai-orchestration";
import {CREATE_AI_ORCHESTRATION} from "../../../graphql/mutations/ai-mutations";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {GET_AI_ORCHESTRATIONS} from "../../../graphql/queries/ai-orchestration-queries";
import {SelectSourcesModal} from "../select-sources-modal";
import {Campaign, TrainingSet} from "../../../models/ai-model";
import {isTrainingSet} from "../../../canvas/chat-bubble/sources-section";
import {InputChip} from "../../../shared/components/input-chip";
import {useToastContext} from "../../../context/toast-context";
import {useWorkflowEventsContext} from "../../../context/workflow-contexts";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./create-workflow-modal.module.scss";

export interface CreateWorkflowModalProps extends BaseModalProps {
	onAdd: (id: string) => void;
}

export const CreateWorkflowModal = ({isOpen, onClose, onAdd}: CreateWorkflowModalProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {updateToast} = useToastContext();
	const {
		workflowCreationStarted,
		agentCreationProgress,
		workflowCreationCompleted,
		workflowCreationError,
		setActiveOrchestrationId
	} = useWorkflowEventsContext();
	const {
		workspace: {id: workspaceId},
	} = useWorkspaceContext();
	const [changes, setChanges] = useState({
		name: "",
		objective: "",
	});

	const [isSourcesModalOpen, setIsSourcesModalOpen] = useState(false);
	const [sources, setSources] = useState<(Campaign | TrainingSet)[]>([]);
	const [creationProgress, setCreationProgress] = useState<{current: number; total: number; message: string} | null>(null);
	const [createWorkflow] = useMutation<CreateAiOrchestrationData, CreateAiOrchestrationVars>(
		CREATE_AI_ORCHESTRATION,
		{
			refetchQueries: [GET_AI_ORCHESTRATIONS],
			onError: (error) => {
				console.error('Error creating workflow:', error);
				setCreationProgress(null);
				setActiveOrchestrationId(undefined);
				updateToast({
					description: "Failed to create workflow, please try again later.",
					type: "failure",
				});
			},
		},
	);

	const {
		results: validation,
		validate,
		clearErrors,
	} = useValidation(changes, {
		name: {
			ruleset: {
				required: true,
				max: 150,
			},
		},
		objective: {
			ruleset: {
				required: true,
			},
		},
	});

	const [autoWorkflow, setAutoWorkflow] = useState(true);

	const handleChangeField = (value: string, field: string): void => {
		setChanges({
			...changes,
			[field]: value,
		});
	};

	const handleSave = async (): Promise<void> => {
		if (!validate()) {
			return;
		}

		try {
			console.log('Creating workflow...');
			
			setCreationProgress({
				current: 0,
				total: 1,
				message: "🚀 Starting workflow creation..."
			});

			// Generate a valid GUID for the orchestration
			const orchestrationId = crypto.randomUUID();
			console.log('Generated orchestration ID:', orchestrationId);

			// Set up subscription first
			setActiveOrchestrationId(orchestrationId);

			// Fire and forget the mutation - we'll rely on subscription events
			createWorkflow({
				variables: {
					workspaceId,
					name: changes.name,
					description: changes.objective,
					trainingSetIds: sources.filter(isTrainingSet).map(s => s.id),
					surveyIds: sources.filter(s => !isTrainingSet(s)).map(s => s.id),
					autoWorkflow,
					orchestrationId,
				},
			}).catch(error => {
				// Only log the error, don't throw it
				console.error('Mutation error:', error);
			});

			console.log('Workflow creation initiated with ID:', orchestrationId);

		} catch (error) {
			console.error('Error in handleSave:', error);
			setCreationProgress(null);
			setActiveOrchestrationId(undefined);
		}
	};

	// Handle workflow creation events
	React.useEffect(() => {
		const currentId = workflowCreationStarted?.orchestrationId || 
			agentCreationProgress?.orchestrationId || 
			workflowCreationCompleted?.orchestrationId || 
			workflowCreationError?.orchestrationId;

		if (!currentId) return;

		if (workflowCreationStarted?.orchestrationId === currentId) {
			console.log('Workflow creation started:', workflowCreationStarted);
			setCreationProgress({
				current: 0, 
				total: 100, 
				message: "✨ Preparing your intelligent workflow"
			});
		}

		if (agentCreationProgress?.orchestrationId === currentId) {
			console.log('Agent creation progress:', agentCreationProgress);
			
			// Extract the clean message
			const message = agentCreationProgress.agentName
				.replace(/^[⠋⠙⠹⠸⠼⠴⠦⠧⠇⠏]\s*/, '') // Remove spinner characters
				.trim();

			setCreationProgress({
				current: agentCreationProgress.progress,
				total: 100,
				message,
			});
		}

		if (workflowCreationCompleted?.orchestrationId === currentId) {
			console.log('Workflow creation completed:', workflowCreationCompleted);
			setCreationProgress({
				current: 100,
				total: 100,
				message: "✅ Workflow created successfully"
			});
			setTimeout(() => {
				onAdd(workflowCreationCompleted.orchestrationId);
				handleClose();
			}, 1000); // Short delay to show completion
		}

		if (workflowCreationError?.orchestrationId === currentId) {
			console.log('Workflow creation error:', workflowCreationError);
			setCreationProgress(null);
			setActiveOrchestrationId(undefined);
			updateToast({
				description: `Error creating workflow: ${workflowCreationError.error}`,
				type: "failure",
			});
		}
	}, [workflowCreationStarted, agentCreationProgress, workflowCreationCompleted, workflowCreationError]);

	const handleClose = (): void => {
		console.log('Closing modal, cleaning up state...');
		setChanges({
			name: "",
			objective: "",
		});
		setCreationProgress(null);
		setActiveOrchestrationId(undefined);
		clearErrors();
		onClose();
	};

	const handleSelectSources = (campaings: Campaign[], trainingSets: TrainingSet[]): void => {
		const sources = [...campaings, ...trainingSets];
		setSources(sources);
	};

	const formContentRef = React.useRef<HTMLDivElement>(null);
	const [hasScroll, setHasScroll] = useState(false);

	// Check if content is scrollable
	const checkScroll = React.useCallback(() => {
		if (formContentRef.current) {
			const element = formContentRef.current;
			setHasScroll(element.scrollHeight > element.clientHeight);
		}
	}, []);

	// Check scroll on content changes
	React.useEffect(() => {
		checkScroll();
		// Add resize observer to check scroll when container size changes
		const resizeObserver = new ResizeObserver(checkScroll);
		if (formContentRef.current) {
			resizeObserver.observe(formContentRef.current);
		}
		return () => resizeObserver.disconnect();
	}, [checkScroll, changes, sources]);

	const handleRemoveSource = (source: TrainingSet | Campaign): void => {
		setSources(sources.filter(s => s.id !== source.id));
	};

	const renderSource = (source: TrainingSet | Campaign) => {
		if (isTrainingSet(source)) {
			return (
				<InputChip
					icon={<DocumentsFolderFileIcon />}
					key={source.id}
					onRemove={() => handleRemoveSource(source)}
					label={source.alias}
					variant="squared"
					size="default"
				/>
			);
		}

		return (
			<InputChip
				variant="squared"
				icon={<HornMegaphoneCampaignIcon />}
				key={source.id}
				onRemove={() => handleRemoveSource(source)}
				label={source.name}
				size="default"
			/>
		);
	};

	return (
		<>
			<Modal
				size="medium"
				isOpen={isOpen}
				onClose={handleClose}
				//title="Create New Workflow"
				className={styles.modal}
				backgroundColor="transparent"
			>
				<div style={{color: isDarkMode ? "#BFBFBF" : "inherit"}} className={`${styles.cardContainer} ${creationProgress ? styles.flipped : ''}`}>
					<div className={`${styles.cardSide} ${styles.cardFront}`}>
						<div className={styles.form}>
							<div className={`${styles.formWrapper} ${hasScroll ? styles.hasScroll : ''}`}>
								<div 
									ref={formContentRef}
									className={styles.formContent}
								>
									<Input
										placeholder="My workflow"
										label="Workflow Name"
										value={changes.name}
										onChange={(value: string) => handleChangeField(value, "name")}
										validation={validation.name}
									/>

									<div className={styles.objectiveWrapper}>
										<div className={styles.textContainer}>
											<Body color="text-secondary" size="s" type="medium">
												Objective
											</Body>
											<Body color="text-tertiary" size="s" type="regular">
												Define the overall objective for this workflow. This doesn&apos;t need to be highly
												specific, as you&apos;ll be able to assign detailed tasks to each agent later on.
											</Body>
										</div>
										<TextArea
											placeholder="e.g., Enhance marketing insights or Analyze customer feedback"
											value={changes.objective}
											onChange={(value: string) => handleChangeField(value, "objective")}
											validation={validation.objective}
										/>
									</div>

									<div className={styles.sourcesWrapper}>
										<div className={styles.textContainer}>
											<Body color="text-secondary" size="s" type="medium">
												Sources
											</Body>
											<Body color="text-tertiary" size="s" type="regular">
												Link appropriate sources for your workflow. The entire workflow will have access to
												these added sources, such as campaigns or datasets, to ensure comprehensive data
												integration.
											</Body>
										</div>

										{sources.length > 0 && <div className={styles.sourcesSection}>{sources.map(renderSource)}</div>}

										<Button
											className={styles.addSource}
											leftIcon={<PlusSmallIcon />}
											variant="text"
											onClick={() => setIsSourcesModalOpen(true)}
										>
											Add source
										</Button>
									</div>

									{/*<Checkbox
										text="Auto Workflow"
										checked={autoWorkflow}
										onChange={e => setAutoWorkflow(e.target.checked)}
										className={styles.checkboxText}
									/>*/}
								</div>
							</div>

							<div className={styles.footer}>
								<Button className={styles.button} variant="outlined" onClick={handleClose}>
									Cancel
								</Button>

								<Button className={styles.button} onClick={handleSave}>
									Create Workflow
								</Button>
							</div>
						</div>
					</div>
					<div className={`${styles.cardSide} ${styles.cardBack}`}>
						<div className={styles.progressContainer}>
							<div className={styles.spinner} />
							<div className={styles.messageContainer}>
								<div className={styles.message} key={creationProgress?.message}>
									{creationProgress?.message.replace(/^Creating agent:\s*/i, '')}
								</div>
							</div>
							<div className={styles.progressBar}>
								<div 
									className={styles.progressBarFill} 
									style={{
										width: `${creationProgress ? (creationProgress.current / creationProgress.total) * 100 : 0}%`
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</Modal>

			<SelectSourcesModal
				isOpen={isSourcesModalOpen}
				onClose={() => setIsSourcesModalOpen(false)}
				currentSourceIds={sources.map(s => s.id)}
				onSelect={handleSelectSources}
			/>
		</>
	);
};
