import React, {ReactElement, cloneElement} from "react";
import {useLocation} from "react-router";
import classNames from "classnames/bind";

import {Body} from "../../../../shared/v2/typography";
import {NavLink} from "../../../../route";
import {useThemeMode} from "../../../../context/theme-mode-context";

import styles from "./button-group-element.module.scss";
import {Badge} from "../../../../workspace-settings/components/model-card/badge";

const bStyles = classNames.bind(styles);

export interface ButtonGroupElementProps {
	to?: string;
	icon?: ReactElement;
	text: string;
	end?: boolean;
	onClick?: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	state?: any;
	iconClassName?: string;
	withSearch?: boolean;
	active?: boolean;
	size?: "small" | "default";
	isBeta?: boolean;
}

export const ButtonGroupElement = ({
	to,
	icon,
	text,
	state,
	end,
	iconClassName,
	withSearch = false,
	onClick,
	active,
	size = "default",
	isBeta,
}: ButtonGroupElementProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const location = useLocation();

	const renderBetaBadge = () => (
        <Badge text="beta" tooltip="This feature is in beta and may not work perfectly yet. We’d love your feedback to help us refine and improve it!"/>
	);

	return (
		<div>
			{(to || to === "") &&
				<NavLink
					to={to}
					className={bStyles("wrapper", size, {isDarkMode})}
					activeClassName={styles.active}
					omitSearch={!withSearch}
					workspace
					end={end}
					state={state}
				>
					{icon && cloneElement(icon, {className: bStyles("icon", size, iconClassName)})}
					<Body
						size="s"
						type="medium"
						className={styles.text}
					>
						{text}
					</Body>
					{isBeta && renderBetaBadge()}
				</NavLink>
			}
			{onClick &&
				<div
					className={bStyles("wrapper", size, {isDarkMode, active})}
					onClick={onClick}
				>
					{icon && cloneElement(icon, {className: bStyles("icon", size, iconClassName)})}
					<Body
						size="s"
						type="medium"
						className={styles.text}
					>
						{text}
					</Body>
					{isBeta && renderBetaBadge()}
				</div>
			}
		</div>
	)
};
