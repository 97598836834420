/* eslint-disable react/prop-types */
import React, {ReactElement, useContext, useMemo, useRef, useState} from "react";
import {gql, NetworkStatus, useMutation} from "@apollo/client";
import {
	ADD_SURVEY_MEMBERS,
	REMOVE_SURVEY_MEMBER,
} from "../../../graphql/mutations/survey-mutations";
import {GET_SURVEY_MEMBERS} from "../../../graphql/queries/survey-queries";
import {
	AddSurveyMemberData,
	AddSurveyMemberVars,
	SurveyMember,
	SurveyMembersData,
	SurveyMemberStatus,
	UserRoles,
} from "../../../models/user";
import {
	formatLong,
	Heading,
	InputWithButton,
	NewAvatar,
	Options,
	Select,
} from "../../../shared";
import {AlignText} from "../../../shared/typography/align-text";
import {InviteToSurveyModal} from "../../../modals/invite-to-survey";
import {StatusBanner} from "../../components/status-banner";
import {SurveyContext} from "../../../context/survey-context";
import {ToastContext} from "../../../context/toast-context";

import styles from "./survey-participants.module.scss";
import {QrModal} from "../../modals";
import {Column, HeaderGroup, Row} from "react-table";
import {useLoadingQuery} from "../../../hooks";
import {PaginatedTable} from "../../../shared/components/table/paginated-table";
import {EmailModal} from "../../modals/emailer";
import {updateCacheDeletePageItem} from "../../../shared/utility/update-cache";
import {RemoveSurveyMemberData, RemoveSurveyMemberVars, SurveyMembersSort} from "../../../models/survey";
import {ActionsButton} from "../../../contacts/components/actions-button";
import {PromoteModal} from "../../modals/promote";
import config from "../../../config";
import {Card} from "../../../shared/layout/card";
import {UserContext} from "../../../context/user-context";
import {Button} from "../../../shared/v2";
import {EnvelopeIcon, QrCodeIcon} from "../../../icons";

const PAGE_SIZE = 100;

const filterOptions = [{
	text: "All",
	value: undefined,
}, {
	text: "Invited",
	value: SurveyMemberStatus.INVITED,
}, {
	text: "Partial",
	value: SurveyMemberStatus.PARTIAL,
}, {
	text: "Completed",
	value: SurveyMemberStatus.COMPLETED,
}];

const SurveyParticipants = (): ReactElement => {
	const {survey: {id: surveyId}} = useContext(SurveyContext);
	const {updateToast} = useContext(ToastContext);
	const {user: {role}} = useContext(UserContext);
	const inputRef = useRef<HTMLInputElement>(null);


	const [showQr, setShowQr] = useState(false);
	const [showInvite, setShowInvite] = useState(false);
	const [showPromote, setShowPromote] = useState(false);
	const [statusFilter, setStatusFilter] = useState<undefined | SurveyMemberStatus>(undefined);
	const [currentPage, setCurrentPage] = useState(0);
	const [sortBy, setSortBy] = useState(SurveyMembersSort.DEFAULT);
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const [selectedRows, setSelectedRows] = useState<Row<SurveyMember>[]>([]);
	const [selectAll, setSelectAll] = useState(false);
	const [pageSize, setPageSize] = useState(PAGE_SIZE);


	const {
		data: memberData,
		handleFetchMore,
		fragment,
		fetchMoreFragment,
		networkStatus,
	} = useLoadingQuery<SurveyMembersData>(GET_SURVEY_MEMBERS, {
		variables: {surveyId, limit: pageSize, filter: {status: statusFilter}, sort: sortBy},
		errorPolicy: "all",
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "cache-and-network",
		nextFetchPolicy: "cache-first",
		what: "Creators",
		fetchMoreClassName: styles.fetchMore,
	});

	const currentData = useMemo(() =>
		memberData?.surveyMembers?.items.slice(
			currentPage * pageSize,
			(currentPage + 1) * pageSize,
		), [memberData, currentPage]);

	const handleToggleInvite = (): void => setShowInvite(prev => !prev);
	const handleToggleQr = (): void => setShowQr(prev => !prev);
	const handleTogglePromote = (): void => setShowPromote(prev => !prev);
	/**
	 * Permissions changes means we need to set errorPolicy so we don't
	 * get an error for read permissions.
	 */
	const [addToSurvey] =
	useMutation<AddSurveyMemberData, AddSurveyMemberVars>(ADD_SURVEY_MEMBERS, {
		errorPolicy: "all",
		onCompleted: data => {
			handleToggleInvite();
			const filterNulls = data.addSurveyMembers.users.filter(v => v !== null);
			const {length} = filterNulls;
			updateToast({
				description: `${length} Creators added`,
				type: "informational",
			});
		},
		onError: error => {
			const {message} = error;
			// "Could not identify object null" appears when trying to add someone that exists
			if (message.includes("null")) {
				handleToggleInvite();
				return;
			}
			updateToast({
				description: message,
				type: "failure",
			});
		},
	});

	const [removeInvitee] =
	useMutation<RemoveSurveyMemberData, RemoveSurveyMemberVars>(REMOVE_SURVEY_MEMBER, {
		errorPolicy: "all",
		onCompleted: data => {
			if (!data) return;
			updateToast({
				description: `${data.removeSurveyMembers.users.length} Creator(s) removed`,
				type: "informational",
			});
		},
	});

	const handleCopy = (): void => {
		if (!inputRef.current) return;
		navigator.clipboard.writeText(inputRef.current.value).then(
			() => updateToast({description: "Copied Link", type: "informational"}),
			() => updateToast({description: "Failed to copy", type: "failure"}),
		);
		updateToast({
			description: "Copied to clipboard",
			type: "informational",
		});
	};

	/**
	 * Handles removing one of the invitees
	 * @param inviteeId The invitee's id to remove
	 */
	const handleRemove = (inviteeId: string): void => {
		removeInvitee({
			variables: {
				surveyId,
				userIds: inviteeId,
			},
			update(cache, {data}) {
				if (!data) return;
				data.removeSurveyMembers.users.forEach(
					userId =>
						updateCacheDeletePageItem(cache, "surveyMembers", "SurveyMember", userId.id, "userId"),
				);
			},
		});
	};

	/**
	 * Function to call mutation to add members to the survey.
	 * Also updates the cache to get an updated list of members on survey
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleInviteSurveyMembers = (emails: string[], anonymous: boolean): Promise<any> => {
		return addToSurvey({
			variables: {
				surveyId,
				emails,
				anonymous,
			},
			update(cache, {data}) {
				if (data) {
					cache.modify({
						fields: {
							/**
							 * Right now it seems like the mutation here always returns email
							 * fields as null, so we can't really update anything.
							 */
							surveyMembers: previous => {
								// A user could be null if they are already in the participant list

								const newItemsRefs = data.addSurveyMembers.users?.filter(user => user !== null)
									.map(user => (
										cache.writeFragment({
											data: user,
											fragment: gql`
												fragment NewUser on User {
													id
													firstName
													lastInitial
													picture {
														id
														badge
													}
												}
											`,
										})
									));
								return {
									...previous,
									items: [...previous.items, ...newItemsRefs],
								};
							},
						},
					});
				}
			},
		});
	};

	const columns = useMemo((): Column<SurveyMember>[] => (
		[
			{
				id: "name",
				Header: "Name",
				accessor: "user",
				Cell: ({row: {original}}) => <NewAvatar user={original.user} showName showTag/>,
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: ({value}) => <span className={styles.status}>{value.toLowerCase()}</span>,
			},
			{
				Header: "Email Sent",
				accessor: "emails",
				Cell: ({value}) => (value.length > 0 ? value.map(log =>
					<p className={styles.email} key={`${log.createdAt}`}>
						{formatLong(log.createdAt)}
					</p>) : (<span>No emails sent</span>)),
			},
			{
				id: "options",
				Header: "",
				disableSortBy: true,
				Cell: ({row: {original}}) => <AlignText align="right">
					<Options
						type="menu-vertical"
						options={[{
							name: "Remove",
							actionOptions: {
								isLink: false,
								onClick: () => handleRemove(original.userId),
							},
							icon: "trash",
						}, {
							name: "Send email",
							actionOptions: {
								isLink: false,
								onClick: () => setSelectedUsers([original.user.id]),
								skip: original.status ===
									SurveyMemberStatus.PARTIAL || original.status === SurveyMemberStatus.COMPLETED,
							},
							icon: "envelope",
							iconFill: "black",
						}]}
					/>
				</AlignText>,
			},
		]
	), []);

	const handleCloseEmailer = (): void => {
		setSelectedRows([]);
		setSelectedUsers([]);
	};

	const handleSort = (value: HeaderGroup<SurveyMember>): void => {
		if (!value.canSort) return;

		switch (value.Header) {
		case "Name":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(SurveyMembersSort.CREATOR_TAG_ASC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(SurveyMembersSort.CREATOR_TAG_DESC);
			setSortBy(SurveyMembersSort.DEFAULT);
			break;
		case "Status":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(SurveyMembersSort.STATUS_ASC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(SurveyMembersSort.STATUS_DESC);
			setSortBy(SurveyMembersSort.DEFAULT);
			break;
		case "Email Sent":
			if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(SurveyMembersSort.LAST_EMAIL_SENT_ASC);
			if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(SurveyMembersSort.LAST_EMAIL_SENT_DESC);
			setSortBy(SurveyMembersSort.DEFAULT);
			break;
		default:
			setSortBy(SurveyMembersSort.DEFAULT);
		}

	};

	const handlePerPageChange = (newPageSize: number): void => {
		setPageSize(newPageSize);
		setCurrentPage(0);
	}

	const canPromote = role === UserRoles.ENTERPRISE_MANAGER || role === UserRoles.SUPPORT;
	return (
		<div className={styles.container}>
			<Card className={styles.actionCard}>
				<Heading size="md">Share</Heading>
				<div>
					{canPromote && <Button
						size="small"
						variant="outlined"
						onClick={handleTogglePromote}
						className={styles.promoteButton}
					>
						Promote
					</Button>
					}
					<InputWithButton
						id="copy-survey-link"
						buttonText="Copy"
						defaultValue={`${config.responder}/#/${surveyId}`}
						readOnly
						ref={inputRef}
						onClick={handleCopy}
						className={styles.link}
					/>
					<Button
						variant="outlined"
						leftIcon={<QrCodeIcon />}
						onClick={handleToggleQr}
					>
							QR Code
					</Button>
				</div>
			</Card>
			<Card className={styles.card}>
				<StatusBanner />
				<header className={styles.actions}>
					<Heading size="md">Email Invitations</Heading>
					<div className={styles.actionButtons}>
						{selectedRows.length > 0 && memberData &&
							<ActionsButton
								text={`Bulk Actions (${selectAll ?
									memberData?.surveyMembers.items.length + memberData?.surveyMembers.remaining
									: selectedRows.length})`}
								options={[
									{
										name: "Email Creators",
										actionOptions: {
											isLink: false,
											onClick: () => setSelectedUsers(selectedRows.map(s => s.original.user.id)),
										},
									},
								]}
							/>
						}
						<Select
							id="filter-status"
							selectedValue={statusFilter}
							options={filterOptions}
							onChange={setStatusFilter}
							size="small"
						/>
						<Button
							id="invite-creators"
							onClick={handleToggleInvite}
							leftIcon={<EnvelopeIcon />}
							className={styles.button}
						>
							Invite Creators
						</Button>
					</div>
				</header>
				{!memberData && fragment || (memberData && currentData &&
				<PaginatedTable
					columns={columns}
					data={currentData}
					pageState={[currentPage, setCurrentPage]}
					pageSize={pageSize}
					totalCount={memberData.surveyMembers.items.length + memberData.surveyMembers.remaining}
					handleFetchMore={handleFetchMore}
					dataLength={memberData.surveyMembers.items.length}
					fetchMoreFragment={fetchMoreFragment}
					selectedValues={selectedRows}
					selectAllState={[selectAll, setSelectAll]}
					onSelectChange={setSelectedRows}
					sortLoading={networkStatus === NetworkStatus.setVariables}
					onSort={handleSort}
					tableClassName={styles.table}
					onPageSizeChange={handlePerPageChange}
				/>)
				}
				<InviteToSurveyModal
					isOpen={showInvite}
					onClose={handleToggleInvite}
					onSend={handleInviteSurveyMembers}
				/>
				<QrModal isOpen={showQr} onClose={handleToggleQr}/>
				<EmailModal
					isOpen={Boolean(selectedUsers.length)}
					onClose={handleCloseEmailer}
					selectedUsers={selectedUsers}
					selectAllState={[selectAll, setSelectAll]}
					status={statusFilter}
				/>
				<PromoteModal isOpen={showPromote} onClose={handleTogglePromote}/>
			</Card>
		</div>
	);
};

export {SurveyParticipants};
