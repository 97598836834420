import React, {HTMLProps} from "react";
import {useChatScrollContext} from "../../../../../context/chat-contexts/chat-scroll-context";
import {useImageHistoryContext} from "../../../../image-studio/contexts";

import styles from "./link-reader.module.scss";
import {useInView} from "react-intersection-observer";
import {Link} from "@/hoc/layout/navigation/link";

const getServiceInfo = (url: string) => {
	if (url.includes("/sd/")) return {name: "Stable Diffusion", color: "#a69cfe"};
	if (url.includes("/dalle/")) return {name: "Dalle", color: "#D0F8AB"};
	if (url.includes("/google/")) return {name: "Imagen", color: "#A5F0FC"};
	return {name: "User Upload", color: "#a69cfe"};
};

const LinkRenderer = ({href, children}: HTMLProps<HTMLAnchorElement>) => {
	const {scrollListToBottom} = useChatScrollContext();
  const {setImageSrc} = useImageHistoryContext();
	const {name, color} = getServiceInfo(href ?? "");

	const isImage = (content: string) => {
		const imageUrlRegex = /(https:\/\/\S+\.(?:png|jpg|jpeg|gif|webp))/i;
		return imageUrlRegex.test(content);
	};

	const handleClick = (href: string | undefined) => {
		if (href) {
			scrollListToBottom();
			setImageSrc(href);
		}
	};

	const {ref, inView} = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

	return (
		<>
			{isImage(href ?? "") ? (
				<>
					<div className={styles.imageContainer} ref={ref}>
						<div
							className={styles.imageLabelContainer}
							style={{backgroundColor: color}}
						>
							{name}
						</div>
						{inView &&
							<img
								className={styles.amazonImg}
								src={href}
								onClick={() => handleClick(href)}
								alt={children?.[0] as string}
								onLoad={() => scrollListToBottom()}
							/>
						}
					</div>
				</>
			) : (
                //@ts-ignore
				<Link to={href} target="_blank" rel="noreferrer">{children}</Link>
			)}
		</>
	);
};

export default LinkRenderer;
