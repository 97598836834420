import React, {ReactElement} from "react";

import {AssistiveChip} from "@/shared/v2/assistive-chip";
import {Label} from "../../manage-labels-modal/labels-form";

import styles from "./file-labels.module.scss";

export interface FileLabelsProps {
  tags: Label[];
  /**
   * Maximum number of tags to display. If -1, all tags will be displayed.
   */
  max?: number;
  onShowAll?: () => void;
}

export const FileLabels = ({tags, max = 2, onShowAll}: FileLabelsProps): ReactElement => {
  const tagsLength = tags?.length || 0;
  if (tagsLength === 0) {
    return <></>;
  }

  const tagsToShow = max === -1 ? tags : tags.slice(0, max);

  return (
    <div className={styles.tags}>
      {tagsToShow.map((tag, index) => (
        <AssistiveChip key={index} text={`${tag.key}: ${tag.value}`} />
      ))}
      {tagsLength > max && max !== -1 && onShowAll && (
        <AssistiveChip
          className={styles.displayMoreTags}
          style="outline"
          text={`+${tagsLength - max}`}
          onClick={onShowAll}
        />
      )}
    </div>
  );
}
