import classNames from "classnames/bind";
import {AnimatePresence, motion} from "framer-motion";
import React, {
	FormEvent,
	ReactElement,
	useCallback,
	useEffect,
	useState,
} from "react";

import {
	useChatConversationContext,
	useChatFollowUpsContext,
	useChatPersonaContext,
	useChatSendQuestionContext,
	useChatImageContext,
} from "../../context/chat-contexts";
import {KeyboardEventMiddlewareContextProvider} from "../../context/keyboard-event-middleware-context";
import {useThemeMode} from "../../context/theme-mode-context";
import {useWorkspaceContext} from "../../context/workspace-context";
import config from "../../config";
import {PlusIcon} from "../../icons";
import {CHAT_COMMANDS} from "../../shared/constants/constants";
import {ButtonIcon} from "../../shared/v2";
import {ToggleSwitch} from "../../shared/components/toggle-switch";
import {Tooltip} from "../../shared/v2/tooltip";
import {transition, variants} from "../chat-view/animation-helpers";
import {ActionsDropdown} from "./actions-dropdown";
import CommandTextArea from "./command-text-area";
import {PersonaTabWrapper} from "./persona-tab-wrapper";
import {SourcesSection} from "./sources-section";
import {SubmitButton} from "./submit-button";
import {UploadedImageSection} from "./uploaded-image-section";

import styles from "./chat-bubble.module.scss";

const cx = classNames.bind(styles);

export type ChatBubbleContext = "home" | "chat";

export interface ChatInputBoxProps {
	className?: string;
	promptValue?: string;
	clearPrompt: () => void;
	context: ChatBubbleContext;
}

export const ChatBubble = ({promptValue, clearPrompt, context}: ChatInputBoxProps): ReactElement => {
	const {conversation} = useChatConversationContext();
	const {sendQuestion, isSendingQuestion} = useChatSendQuestionContext();
	const {followUps} = useChatFollowUpsContext();
	const {workspace} = useWorkspaceContext();
	const {isDarkMode} = useThemeMode();
	const {image} = useChatImageContext();

	const [value, setValue] = useState("");
	const [smartToggle, setSmartToggle] = useState(false);
	const isDisabled = !workspace.id || (!value.length && !image) || isSendingQuestion;
	const {personas} = useChatPersonaContext();

	useEffect(() => {
		if (promptValue) {
			setValue(promptValue);
		}
	}, [promptValue]);

	const clearInput = () => {
		setValue("");
		clearPrompt();
	};

	const handleResponse = useCallback(async (e?: FormEvent<HTMLFormElement>): Promise<void> => {
		e?.preventDefault();

		if (isDisabled) {
			return;
		}

		clearInput();
		await sendQuestion(value, {smartPromptEnabled: smartToggle});
	}, [isDisabled, value, sendQuestion, conversation, setValue, smartToggle]);

	const handleToggleClick = useCallback(() => {
		setSmartToggle(prev => !prev);
	}, []);

	const homeTextAreaSection = (
		<>
			<UploadedImageSection />
			<KeyboardEventMiddlewareContextProvider>
				<CommandTextArea
					followUps={followUps}
					value={value}
					onChange={setValue}
					placeholder={'Ask anything...'}
					commands={CHAT_COMMANDS}
					personas={personas}
					handleSubmit={handleResponse}
					className={styles.textarea}
				/>
			</KeyboardEventMiddlewareContextProvider>
		</>
	);

	const chatAndSourcesWrapperVariants = variants({
		home: {
			opacity: 0,
			y: "-100%",
		},
		chat: {
			opacity: 1,
			y: "0%",
		}
	}, 2);

	return (
		<PersonaTabWrapper>
			<div className={cx("chatBubble", {isDarkMode})}>
				<AnimatePresence>
					{context === "home" && (
						<motion.div
							exit={{
								height: 0,
								margin: 0,
								opacity: 0,
								padding: 0,
								transition: transition(1),
							}}
							className={styles.homeContentWrapper}
						>
							{homeTextAreaSection}
						</motion.div>
					)}
				</AnimatePresence>
				<div className={styles.bottomLine}>
					<ActionsDropdown>
						<ButtonIcon
							icon={<PlusIcon />}
							className={styles.icon}
							aria-label='plus-button'
						/>
					</ActionsDropdown>

					<div className={styles.chatContentWrapper}>
						<motion.div
							className={styles.chatAndSourcesWrapper}
							variants={chatAndSourcesWrapperVariants}
						>
							{
								context === "chat" && (
									<>
										<UploadedImageSection />

										<KeyboardEventMiddlewareContextProvider>
											<CommandTextArea
												followUps={followUps}
												value={value}
												onChange={setValue}
												placeholder={'Ask anything...'}
												commands={CHAT_COMMANDS}
												personas={personas}
												handleSubmit={handleResponse}
											/>
										</KeyboardEventMiddlewareContextProvider>
									</>
								)
							}
						</motion.div>
						<SourcesSection />
					</div>

					{false && (
						<Tooltip
							content={
								<div style={{maxWidth: "300px"}}>
									Smart Prompt will try to use the appropiate Tools, Datasets, or Image functionalities to best generate an appropriate response. This can be toggled off if you wish to only use the Tools explicitly (via the '/' commands)
								</div>
							}
						>
							<button 
								type="button"
								className={styles.toggleWrapper}
								onClick={handleToggleClick}
								aria-label={`Smart Prompt - ${smartToggle ? 'enabled' : 'disabled'}`}
							>
								<div className={styles.toggleContainer}>
									<span className={styles.toggleLabel}>Smart Prompt</span>
									<ToggleSwitch
										id="smart-toggle"
										isChecked={smartToggle}
										onChange={handleToggleClick}
									/>
								</div>
							</button>
						</Tooltip>
					)}

					<SubmitButton context={context} onSubmit={handleResponse} />
				</div>
			</div>
		</PersonaTabWrapper>
	);
};
