// TOOD: remove if not used
import React, {ReactElement} from "react";
import {Icon} from "../..";
import styles from "./pagination.module.scss";
import classnames from "classnames/bind";

const bStyles = classnames.bind(styles);

export interface PaginationProps {
	currentPage: number;
	totalCount: number;
	pageSize: number;
	onChange: (page: number) => void;
}

const Pagination = (props: PaginationProps): ReactElement | null => {
	const {currentPage, onChange, totalCount, pageSize} = props;

	const siblingCount = 1;
	const totalPageCount = Math.ceil(totalCount / pageSize);
	const pages: ReactElement[] = [];

	for (let i = 0; i < totalPageCount; i++) {
		if (i && i < currentPage - siblingCount) {
			pages.push(<div key={i} className={styles.dots}>...</div>);
			i = currentPage - siblingCount;
		}
		if (i > currentPage + siblingCount && i < totalPageCount - 1) {
			pages.push(<div key={i} className={styles.dots}>...</div>);
			i = totalPageCount - 1;
		}
		pages.push(<div
			className={bStyles("circle", {active: i === currentPage})}
			key={i}
			onClick={(): void => onChange(i)}
		>
			<span>{i + 1}</span>
		</div>);
	}

	const handleNextPage = (): void => {
		onChange(currentPage + 1);
	};

	const handlePreviousPage = (): void => {
		onChange(currentPage - 1);
	};

	if (pages.length < 2) {
		return null;
	}

	return (
		<div className={styles.pagination}>
			<Icon
				name="left-arrow"
				size="extrasmall"
				fill="var(--batterii-grey-light)"
				isClickable
				clicked={handlePreviousPage}
				disabled={currentPage === 0}
			/>

			<div className={styles.pages}>
				{pages}
			</div>
			<Icon
				name="right-arrow"
				size="extrasmall"
				fill="var(--batterii-grey-light)"
				isClickable
				clicked={handleNextPage}
				disabled={currentPage === pages.length}
			/>
		</div>
	);
};

export {Pagination};
