import {useQuery} from "@apollo/client";
import React, {ReactNode, createContext, useCallback, useEffect, useMemo, useState} from "react";

import {GET_TRAINING_SETS} from "../../graphql/queries/ai-models-queries";
import {TrainingSet} from "../../models";
import {useChatConversationContext} from "./chat-conversation-context";
import {useSearchParams} from "react-router-dom";
import {useWorkspaceContext} from "../../context/workspace-context";

export interface ChatTrainingSetContextValue {
	isLoading: boolean;
	trainingSets: TrainingSet[];
	activeTrainingSets?: TrainingSet[];
	refetch: () => Promise<TrainingSet[]>;
}

export const ChatTrainingSetContext =
  createContext<ChatTrainingSetContextValue | undefined>(undefined);

export const ChatTrainingSetContextProvider = (
	{children}: {children: ReactNode},
): React.ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {conversation} = useChatConversationContext();
	const [searchParams, setSearchParams] = useSearchParams();
	const [activeTrainingSets, setActiveTrainingSets] = useState<TrainingSet[] | undefined>();

	const {
		data: {trainingSets = []} = {},
		loading: isLoading,
		refetch,
	} = useQuery<{
		trainingSets: TrainingSet[];
	}>(GET_TRAINING_SETS, {
		variables: {
			workspaceId,
		},
	});

	const handleRefetch = useCallback(async () => {
		const {data: {trainingSets: result}} = await refetch();

		return result;
	}, [refetch]);

	const savedTrainingSets = useMemo(() => {
		if (trainingSets.length === 0) {
			return [];
		}

		if (conversation) {
			return conversation.trainingSets.map(({id}) => {
				const dataset = trainingSets.find(trainingSet => trainingSet.id === id);

				if (!dataset) {
					throw new Error(`Training set with id ${id} not found`);
				}

				return dataset;
			});
		} else {
			const trainingSetIds = searchParams.get("trainingSetIds") || "";
			return trainingSets.filter(trainingSet => trainingSetIds.includes(trainingSet.id));
		}
	}, [
		conversation?.trainingSets,
		trainingSets.map(({id}) => id).join(","),
		searchParams.get("trainingSetIds"),
	]);

	useEffect(() => {
		setActiveTrainingSets(savedTrainingSets);
	}, [savedTrainingSets]);

	useEffect(() => {
		const trainingSetIds = searchParams.get("trainingSetIds")?.split(",");

		if (conversation && trainingSetIds?.length) {
			setSearchParams(prev => {
				prev.delete("trainingSetIds");
				return prev;
			})
		}
	}, [conversation?.id, searchParams.get("trainingSetIds")]);

	return (
		<ChatTrainingSetContext.Provider
			value={{
				activeTrainingSets,
				trainingSets,
				isLoading,
				refetch: handleRefetch,
			}}
		>
			{children}
		</ChatTrainingSetContext.Provider>
	);
};

export const useChatTrainingSetContext = (): ChatTrainingSetContextValue => {
	const context = React.useContext(ChatTrainingSetContext);

	if (context === undefined) {
		throw new Error(
			"useChatTrainingSetContext must be used within a ChatTrainingSetContextProvider",
		);
	}

	return context;
};
