/* eslint-disable */
import {LayoutContainer, Option, Options, Select} from "../../../shared";
import {
	GET_SHOW_INVITE,
} from "../../../graphql/queries/client-queries";
import React, {ReactElement, useContext, useMemo, useState} from "react";
import {toggleShowInviteModal} from "../../../cache";
import {NetworkStatus, useMutation, useQuery} from "@apollo/client";
import {GET_WORKSPACE_MEMBERS} from "../../../graphql/queries/queries";
import {INVITE_USERS, SET_WORKSPACE_ROLE} from "../../../graphql/mutations/mutations";
import {InviteModal} from "../../../contacts/modals/invite-member";
import {Link} from "react-router-dom";
import {REMOVE_WORKSPACE_MEMBER} from "../../../graphql/mutations/workspace-settings-mutations";
import {RemoveMemberModal} from "../../../modals/remove-member";
import {ToastContext} from "../../../context/toast-context";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {WorkspaceMember, WorkspaceMemberPageData, WorkspaceMembersSort, WorkspacePlan} from "../../../models/workspace";
import {WorkspaceRole} from "../../../models/filter";
import styles from "./workspace-members.module.scss";
import {updateObject} from "../../../shared/utility";
import {Body, Button, Header} from "../../../shared/v2";
import {PlusIcon} from "../../../icons";
import {useMount} from "../../../hooks/useMount";
import {PaginatedTable} from "../../../shared/components/table/paginated-table";
import {Column, HeaderGroup} from "react-table";
import {AlignText} from "../../../shared/typography/align-text";
import {useLoadingQuery} from "../../../hooks";

const PAGE_SIZE = 100;


const EditableCell = ({value: initialValue, row: {index, original}, column: {handleCancel, handleUpdate}, editableRowIndex, roleEditList}): JSX.Element => {
	const [value, setValue] = useState(initialValue);
	const handleSave = () => handleUpdate(original.id, value);

	return index === editableRowIndex ? (
		<div className={styles.editable}>
			<Select
				id="change-role-select"
				options={roleEditList}
				selectedValue={value}
				onChange={setValue}
			/>
			<Body className={styles.save} onClick={handleSave}>Save</Body>
			<Body className={styles.cancel} onClick={handleCancel}>Cancel</Body>
		</div>
	) : <Body className={styles.role}>{original.workspaceRole.toLocaleLowerCase()}</Body>
};

/**
 * @returns Workspace members page. Displays differently for admins and creators.
 */
const WorkspaceMembersPage = (): ReactElement => {
	const [currentPage, setCurrentPage] = useState(0);
	const [userIdForDelete, setUserIdForDelete] = useState("");
	const [editableRowIndex, setEditableRowIndex] = useState<number>();
	const [sortBy, setSortBy] = useState(WorkspaceMembersSort.DEFAULT);
	const [pageSize, setPageSize] = useState(PAGE_SIZE);

	const {workspace: {id, myRole, plan}, permissions} = useWorkspaceContext();
	const {data, handleFetchMore, fragment, fetchMoreFragment, networkStatus} = useLoadingQuery<WorkspaceMemberPageData>(
		GET_WORKSPACE_MEMBERS,
		{
			variables: {workspaceId: id, sort: sortBy},
			what: "members",
			fetchPolicy: "network-only",
			nextFetchPolicy: "cache-first",
			notifyOnNetworkStatusChange: true,
		},
	);
	const {updateToast} = useContext(ToastContext);
	const [removeWorkspaceMember] = useMutation(REMOVE_WORKSPACE_MEMBER, {
		onCompleted: () => updateToast({type: "informational", description: "Member removed"}),
	});
	const [saveRoleUpdate] = useMutation(SET_WORKSPACE_ROLE, {
		onCompleted: () => updateToast({description: "Updated role", type: "informational"}),
	});

	const [inviteWorkspaceMember] = useMutation(INVITE_USERS, {
		onCompleted: invites => {
			const {length} = invites.inviteEmailsToWorkspace;
			updateToast({
				description: `${length} Members invited`,
				type: "informational",
			});
		},
		refetchQueries: [{
			query: GET_WORKSPACE_MEMBERS,
			variables: {workspaceId: id},
		}],
	});

	useMount(() => {document.title = "Vurvey - Manage Users"})

	// Client query for the invite modal. Probably remove soon.
	const toggleInviteModal = useQuery(GET_SHOW_INVITE);
	const {toggleShowInviteModal: inviteModal} = toggleInviteModal.data;

	/**
	 * Determines if logged in user can add members or not
	 */
	const canAddMembers = useMemo((): boolean => {
		if (permissions) {
			return permissions.some(value => value.includes("add"));
		}
		return false;
	}, [permissions]);
	/**
	 * Determines if logged in user can edit a member
	 */
	const canEditMembers = useMemo((): boolean => {
		if (permissions) {
			return permissions.some(value => value.includes("make"));
		}
		return false;
	}, [permissions]);


	const roleEditList = useMemo((): Option[] | undefined => {
		if (canEditMembers && permissions) {
			let optionsList = [] as Option[];

			optionsList.push({text: "Administrator", value: WorkspaceRole.ADMINISTRATOR});
			optionsList.push({text: "Manager", value: WorkspaceRole.MANAGER});

			if (myRole === WorkspaceRole.OWNER) {
				optionsList.unshift({text: "Owner", value: WorkspaceRole.OWNER});
			}
			return optionsList;
		}
	}, [canEditMembers, permissions]);

	const toggleRemoveModal = (): void => setUserIdForDelete("");
	/**
	 * Function just updates the boolean value of the invite modal
	 */
	const toggleInvite = (): void => {
		const currentValue = toggleShowInviteModal();
		toggleShowInviteModal(!currentValue);
	};

	/**
	 * Calls the mutation to remove the selected user and updates our cache
	 * to reflect the changes.
	 */
	const handleRemoveMember = (): void => {
		removeWorkspaceMember({
			variables: {workspaceId: id, userIds: userIdForDelete},
			update(cache, {data: {removeWorkspaceMembers: {users}}}) {
				const [deletedUser] = users;
				const cachedUser = cache.identify(deletedUser);
				cache.modify({
					fields: {
						workspaceMembers(existingMembers = [], {readField}) {
							const updatedMembers = updateObject(existingMembers, {
								items: existingMembers.items.filter(
									userRef => deletedUser.id !== readField("id", userRef),
								),
							});
							return updatedMembers;
						},
					},
				});
				cache.evict({id: cachedUser});
			},
		});
		setUserIdForDelete("");
	};

	const handleInviteMember = (emails: string[], role?: WorkspaceRole): void => {
		inviteWorkspaceMember({
			variables: {workspaceId: id, emails, role},
		});
	};

	const handleUpdateRole = (userId: string, role: string): void => {
		saveRoleUpdate({
			variables: {workspaceId: id, userId, role},
			onCompleted: () => {
				updateToast({description: "Role updated for user", type: "informational"});
				setEditableRowIndex(undefined);
			}
		});
	};

	const cancelUpdateCallback = (): void => setEditableRowIndex(undefined);

	const currentData = useMemo(() => (
		data?.workspaceMembers?.items.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
	), [data, currentPage]);
	const columns = useMemo((): Column<WorkspaceMember>[] => {
		const column: any[] = [
			{
				id: "name",
				Header: "Name",
				accessor: "name",
				Cell: ({value}) => <Body size="s">{value}</Body>
			},
			{
				id: "email",
				Header: "Email",
				accessor: "email",
				Cell: ({value}) => <Body size="s">{value}</Body>
			},
			{
				id: "role",
				Header: "Role",
				accessor: "workspaceRole",
				disableSortBy: true,
				handleCancel: cancelUpdateCallback,
				handleUpdate: handleUpdateRole,
				Cell: props => <EditableCell {...props} roleEditList={roleEditList}/>
			},
		];
		if (canEditMembers) {
			column.push({
				id: "options",
				Header: "",
				maxWidth: 50,
				disableSortBy: true,
				Cell: ({row: {original, id}}) => <AlignText align="right">
					<Options
						type="menu-vertical"
						options={[{
							name: "Edit",
							actionOptions: {onClick: () => setEditableRowIndex(Number(id))},
						}, {
							name: "Remove",
							actionOptions: {onClick: () => setUserIdForDelete(original.id)},
						}]}
					/>
				</AlignText>
			});
		}
		return column;
	}, [data]);

	const handleSort = (value: HeaderGroup<any>): void => {
		if (!value.canSort) return;

		switch (value.Header) {
			case "Name":
				if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(WorkspaceMembersSort.NAME_ZA);
				if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(WorkspaceMembersSort.NAME_AZ);
				setSortBy(WorkspaceMembersSort.DEFAULT);
				break;
			case  "Email":
				if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(WorkspaceMembersSort.EMAIL_DESC);
				if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(WorkspaceMembersSort.EMAIL_ASC);
				setSortBy(WorkspaceMembersSort.DEFAULT);
				break;
			default:
				setSortBy(WorkspaceMembersSort.DEFAULT);
		}

	}

	const handlePerPageChange = (newPageSize: number): void => {
		setPageSize(newPageSize);
		setCurrentPage(0);
	}

	return (
		<LayoutContainer theme="dark">
			<div className={styles.container}>
				<header className={styles.header}>
					<Header type="medium">Manage Users</Header>
					{ canAddMembers &&
					plan !== WorkspacePlan.TRIAL && plan !== WorkspacePlan.PENDING &&
						<Button
							onClick={toggleInvite}
							leftIcon={<PlusIcon />}
						>
							Add Users
						</Button>
					}
				</header>
				{
					canAddMembers && (plan === WorkspacePlan.TRIAL ||
						plan === WorkspacePlan.PENDING) &&
					<div className={styles["red-banner"]}>
						<Link
							className={styles.link}
							to={{
								pathname: "workspace/manage-plan",
								search: `workspaceId=${id}`,
							}}>Upgrade</Link> your workspace to add more users
					</div>
				}
				{!data && fragment || (data && currentData) && data.workspaceMembers.items.length > 0 &&
					<PaginatedTable
						columns={columns}
						data={currentData}
						pageSize={pageSize}
						totalCount={data.workspaceMembers.items.length + data.workspaceMembers.remaining}
						editableRowIndex={editableRowIndex}
						dataLength={data.workspaceMembers.items.length}
						handleFetchMore={handleFetchMore}
						fetchMoreFragment={fetchMoreFragment}
						pageState={[currentPage, setCurrentPage]}
						sortLoading={networkStatus === NetworkStatus.setVariables}
						onSort={handleSort}
						onPageSizeChange={handlePerPageChange}
					/>
				}
			</div>
			{ canAddMembers && plan !== WorkspacePlan.TRIAL && plan !== WorkspacePlan.PENDING &&
				<InviteModal
					isMemberInvite
					role={myRole}
					isOpen={inviteModal}
					onClose={toggleInvite}
					handleInvite={handleInviteMember}
				/>
			}
			<RemoveMemberModal
				isOpen={Boolean(userIdForDelete)}
				confirm={handleRemoveMember}
				onClose={toggleRemoveModal}
			/>
		</LayoutContainer>
	);
};

export {WorkspaceMembersPage};
