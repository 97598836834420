import {parse, stringify} from "query-string";
import {useLocation} from "react-router-dom";

import {Build} from "./types";

const config = {
	parseNumbers: true,
	parseBooleans: true,
	arrayFormat: "bracket" as "bracket" | "none",
	skipNull: false,
	skipEmptyString: true,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSearchParams = (): Record<string, any> => {
	const {search} = useLocation();
	return parse(search, config);
};

// Todo: Possibly we can remove it now
export const useBuild = (): Build => {
	const parsedSearch = useSearchParams();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const build = (parsed: any): string => stringify(parsed, config);

	return (to, {search, workspace} = {}) => {
		if (typeof to === "string") {
			// Handle hash in string URLs
			const [pathname, hash] = to.split('#');
			if (!search && !workspace) {
				return hash ? `${pathname}#${hash}` : pathname;
			}
			return {
				pathname,
				search: build(search ? parsedSearch : {}),
				hash: hash ? `#${hash}` : undefined,
			};
		}
		// Handle hash in object URLs
		if (to?.pathname?.includes('#')) {
			const [pathname, hash] = to.pathname.split('#');
			return {
				...to,
				pathname,
				hash: hash ? `#${hash}` : undefined,
				search: build({
					...(search ? parsedSearch : {}),
					...to?.search ?? {},
				}),
			};
		}
		return {
			...to,
			search: build({
				...(search ? parsedSearch : {}),
				...to?.search ?? {},
			}),
		};
	};
};
