
import {useCallback, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

export interface ModalActionsProps {
	init?: boolean;
	search?: string;
}

export const useModalActions = (props?: ModalActionsProps) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const {init = false, search} = props || {};

	const [isOpen, setIsOpen] = useState(init);

	const handleSetIsOpen = useCallback((value: boolean) => {
		// If search is provided, use searchParams to set the value
		if (search) {
			setSearchParams(prev => {
				if (value) {
					prev.set(search, "true");
				} else {
					prev.delete(search);
				}
				return prev;
			}, {replace: true});
		// Otherwise, use setIsOpen to set the value
		} else {
			setIsOpen(value);
		}
	}, [setIsOpen, setSearchParams, search]);

	// If search is provided, set the value based on the searchParams
	useEffect(() => {
		if (search) {
			const isSetOpen = searchParams.get(search);
			if (isSetOpen === "true") {
				setIsOpen(true);
			} else {
				setIsOpen(false);
			}
		}
	}, [search, searchParams, setIsOpen]);

	const open = useCallback(() => handleSetIsOpen(true), [setIsOpen, handleSetIsOpen]);
	const close = useCallback(() => handleSetIsOpen(false), [setIsOpen, handleSetIsOpen]);

	return {
		value: isOpen,
		open,
		close,
	};
};
