import {uniqBy} from "lodash-es";
import React, {ReactElement, ReactNode, createContext, useEffect, useMemo, useState} from "react";

import {ChatSources, useChatSourcesContext} from "@/context/chat-contexts";
import {TrainingSet} from "@/models";
import {TrainingSetFile, TrainingSetMedia, TrainingSetVideo} from "@/models/ai-model";

export interface SelectedSourcesContextValue {
  all: ChatSources;
  selected: ChatSources;
  isDirty: boolean;
  add: (source: TrainingSet[] | TrainingSetMedia[]) => void;
  remove: (source: TrainingSet[] | TrainingSetMedia[]) => void;
  set: (sources: ChatSources) => void;
  save: (soures?: ChatSources) => Promise<void>;
}

export const SelectedSourcesContext =
  createContext<SelectedSourcesContextValue | undefined>(undefined);

export const SelectedSourcesContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {
    sources: allSources,
    active: init,
    normalize: normalizeSources,
    save: saveSources,
  } = useChatSourcesContext();
  const [selected, _setSelected] = useState<ChatSources>({
    trainingSets: [],
    media: {
      files: [],
      videos: [],
    },
  });

  const setSelected = (sources: ChatSources): void => {
    _setSelected(normalizeSources(sources));
  };

  useEffect(() => {
    setSelected({
      trainingSets: [...init.trainingSets],
      media: {
        files: [...init.media.files],
        videos: [...init.media.videos],
      },
    });
  }, [init]);

  const isDirty = useMemo(() => {
    return (
      isChanged(init.trainingSets, selected.trainingSets) ||
      isChanged(init.media.files, selected.media.files) ||
      isChanged(init.media.videos, selected.media.videos)
    )
  }, [init, selected]);

  const addTrainingSets = (trainingSets: TrainingSet[]): void => {
    const currentTrainingSets = selected.trainingSets;
    const newTrainingSets = uniqBy([...currentTrainingSets, ...trainingSets], "id");

    setSelected({
      ...selected,
      trainingSets: newTrainingSets,
    });
  };

  const addMedia = (media: TrainingSetMedia[]): void => {
    const currentMedia = selected.media;
    const newFiles = media.filter(({__typename}) => __typename === "File") as TrainingSetFile[];
    const newVideos = media.filter(({__typename}) => __typename === "TrainingSetVideo") as TrainingSetVideo[];

    setSelected({
      ...selected,
      media: {
        files: uniqBy([...currentMedia.files, ...newFiles], "id"),
        videos: uniqBy([...currentMedia.videos, ...newVideos], "id"),
      },
    });
  };

  const add = (sources: TrainingSet[] | TrainingSetMedia[]): void => {
    if ("trainingSetId" in sources[0]) {
      addMedia(sources as TrainingSetMedia[]);
    } else {
      addTrainingSets(sources as TrainingSet[]);
    }
  };

  const removeTrainingSets = (trainingSets: TrainingSet[]): void => {
    const currentTrainingSets = selected.trainingSets;
    const newTrainingSets = currentTrainingSets.filter(({id}) => !trainingSets.some(({id: tsId}) => tsId === id));

    setSelected({
      ...selected,
      trainingSets: newTrainingSets,
    });
  };

  const removeMedia = (mediaToRemove: TrainingSetMedia[]): void => {
    const newSelected = mediaToRemove.reduce((acc, media) => {
      const activeTrainingSet = acc.trainingSets.find(({id}) => id === media.trainingSetId);

      if (activeTrainingSet) {
        const newTrainingSets = acc.trainingSets.filter(({id}) => id !== media.trainingSetId);
        const filesFromTrainingSet = activeTrainingSet.files.filter(({id}) => id !== media.id);
        const videosFromTrainingSet = activeTrainingSet.videos.filter(({id}) => id !== media.id);

        return {
          trainingSets: newTrainingSets,
          media: {
            files: [
              ...acc.media.files,
              ...filesFromTrainingSet,
            ],
            videos: [
              ...acc.media.videos,
              ...videosFromTrainingSet,
            ],
          },
        }
      }

      if (media.__typename === "File") {
        return {
          ...acc,
          media: {
            files: acc.media.files.filter(({id}) => id !== media.id),
            videos: acc.media.videos,
          },
        };
      }

      if (media.__typename === "TrainingSetVideo") {
        return {
          ...acc,
          media: {
            files: acc.media.files,
            videos: acc.media.videos.filter(({id}) => id !== media.id),
          },
        };
      }

      return acc;
    }, {
      trainingSets: [...selected.trainingSets],
      media: {
        files: [...selected.media.files],
        videos: [...selected.media.videos],
      }
    } as ChatSources)

    setSelected(newSelected);
  };

  const remove = (sources: TrainingSet[] | TrainingSetMedia[]): void => {
    if ("trainingSetId" in sources[0]) {
      removeMedia(sources as TrainingSetMedia[]);
    } else {
      removeTrainingSets(sources as TrainingSet[]);
    }
  };

  const save = async (sources?: ChatSources): Promise<void> => {
    const resource = sources || selected;

    saveSources({
      trainingSets: resource.trainingSets.map(({id}) => id),
      media: {
        files: resource.media.files.map(({id}) => id),
        videos: resource.media.videos.map(({id}) => id),
      },
    });
  };

  return (
    <SelectedSourcesContext.Provider value={{
      all: allSources,
      selected,
      isDirty,
      add,
      remove,
      set: setSelected,
      save,
    }}>
      {children}
    </SelectedSourcesContext.Provider>
  );
};

export const useSelectedSourcesContext = (): SelectedSourcesContextValue => {
  const context = React.useContext(SelectedSourcesContext);

  if (context === undefined) {
    throw new Error(
      "useSelectedSourcesContext must be used within a SelectedSourcesContextProvider",
    );
  }

  return context;
};

function isChanged(original: TrainingSet[], current: TrainingSet[]): boolean;
function isChanged(original: TrainingSetFile[], current: TrainingSetFile[]): boolean;
function isChanged(original: TrainingSetVideo[], current: TrainingSetVideo[]): boolean;
function isChanged(original: (TrainingSet[] | TrainingSetFile[] | TrainingSetVideo[]), current: (TrainingSet[] | TrainingSetFile[] | TrainingSetVideo[])): boolean {
  const originalIds = original.map(({id}) => id);

  return (
    original.length !== current.length ||
    current.some(({id}) => !originalIds.includes(id))
  );
}
