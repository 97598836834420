import React, {ReactElement, ReactNode} from "react";
import {
	ChatCampaignContextProvider,
	ChatConversationContextProvider,
	ChatEventsContextProvider,
	ChatFollowUpsContextProvider,
	ChatImageContextProvider, ChatMentionsContextProvider,
	ChatMessagesContextProvider,
	ChatPersonaContextProvider,
	ChatScrollContextProvider,
	ChatSendQuestionContextProvider,
	ChatSourcesContextProvider
} from "../../context/chat-contexts";
import {ChatHandlers} from "./handlers";
import {ImageHistoryContextProvider} from "../image-studio/contexts";

export interface ChatContextsAndHandlersProps {
  children?: ReactNode;
}

export const ChatContextsAndHandlers = ({children}: ChatContextsAndHandlersProps): ReactElement => {
	return (
		<ImageHistoryContextProvider>
			<ChatScrollContextProvider>
				<ChatEventsContextProvider>
					<ChatMentionsContextProvider>
						<ChatFollowUpsContextProvider>
							<ChatImageContextProvider>
								<ChatConversationContextProvider>
									<ChatPersonaContextProvider>
										<ChatMessagesContextProvider>
											<ChatSourcesContextProvider>
												<ChatCampaignContextProvider>
													<ChatSendQuestionContextProvider>
														<ChatHandlers />
														{children}
													</ChatSendQuestionContextProvider>
												</ChatCampaignContextProvider>
											</ChatSourcesContextProvider>
										</ChatMessagesContextProvider>
									</ChatPersonaContextProvider>
								</ChatConversationContextProvider>
							</ChatImageContextProvider>
						</ChatFollowUpsContextProvider>
					</ChatMentionsContextProvider>
				</ChatEventsContextProvider>
			</ChatScrollContextProvider>
		</ImageHistoryContextProvider>
	);
}
