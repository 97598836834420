import React, {createRef, ReactElement, useMemo, useState} from "react";
import {useMutation} from "@apollo/client";
import {useToastContext} from "@/context/toast-context";

import {Body, Subheader} from "../../../shared/v2/typography";
import {Card, DropdownItem} from "../../../shared/v2";
import {BinDeleteIcon, DotsIcon, FileCorrectIcon, PenEditIcon, SparkAiStarsIcon} from "../../../icons";
import {Dropdown} from "../../../shared/v2/dropdown";
import {EditTrainingSetModal} from "../../modals/edit-training-set-modal";
import {FileUploader} from "../file-uploader";
import {GET_TRAINING_SETS} from "../../../graphql/queries/ai-models-queries";
import {OverflowTooltip} from "../../../shared/v2/tooltip/overflow-tooltip";
import {Separator} from "../../../shared/v2/separator";
import {TrainingSet} from "../../../models/ai-model";
import {useNavigate} from "../../../route";
import {DELETE_TRAINING_SET} from "@/graphql/mutations/ai-mutations";
import {ConfirmActionModal} from "@/shared/v2/modals/confirm-action-modal";

import styles from "./dataset-card.module.scss";

export interface TrainingCardProps {
	trainingSet: TrainingSet
}

export const DatasetCard = ({trainingSet}: TrainingCardProps): ReactElement => {
	const navigate = useNavigate();
	const {updateToast} = useToastContext();

	const subheaderRef = createRef<HTMLDivElement>();
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	const filesCount = trainingSet.files.length + trainingSet.videos.length;

	const [deleteTrainingSet, {loading: isDeleting}] = useMutation(DELETE_TRAINING_SET, {
		onCompleted: () => {
			updateToast({description: "Training set deleted", type: "informational"});
		},
		update(cache, {data: deleteData}) {
			if (deleteData) {
				cache.modify({
					fields: {
						trainingSets(existingTrainingSets = [], { readField }) {
							return existingTrainingSets.filter(
								trainingSetRef => trainingSet.id !== readField('id', trainingSetRef)
							);
						}
					}
				});
			}
		},
	})

	const goToTrainingSet = (): void => {
		navigate(`dataset/${trainingSet.id}`, {workspace: true});
	};

	const openInCopilot = (event?: MouseEvent): void => {
		event?.stopPropagation();
		navigate(
			{
				pathname: "/workflow/conversation",
				search: {trainingSetIds: trainingSet.id},
			},
			{workspace: true},
		);
	};

	const handleDelete = (): void => {
		deleteTrainingSet({
			variables: {
				trainingSetId: trainingSet.id,
			},
		})
	}

	const dropdownItems = useMemo(() => {
		let items: DropdownItem[] = [
			{
				label: "Start a Conversation",
				icon: <SparkAiStarsIcon />,
				onClick: openInCopilot,
				disabled: !filesCount,
			},
			{
				label: "Edit",
				icon: <PenEditIcon />,
				onClick: () => setIsEditModalOpen(true),
				disabled: isDeleting
			}
		]
		if (!filesCount) {
			items.push({
				label: "Delete",
				color: "danger",
				icon: <BinDeleteIcon />,
				onClick: () => setIsDeleteModalOpen(true),
				disabled: isDeleting
			})
		}

		return items;
	}, [filesCount, isDeleting])

	return (
		<>
			<Card
				onClick={goToTrainingSet}
				className={styles.set}
				id={trainingSet.id}
			>
				<Dropdown
					className={styles.dropdown}
					popupClassName={styles.dropdownPopup}
					trigger={<DotsIcon className={styles.trigger} />}
					items={dropdownItems}
					position="bottom-start"
				/>
				<div className={styles.contentWrapper}>
					<OverflowTooltip>
						<Subheader
							ref={subheaderRef}
							type="semibold"
							className={styles.header}
						>
							{trainingSet.alias}
						</Subheader>
					</OverflowTooltip>
					<Body size="s" className={styles.description} color="text-secondary">
						{trainingSet.description}
					</Body>
				</div>
				<div className={styles.bottomSection}>
					<Separator />
					<div className={styles.filesWrapper}>
						<div className={styles.filesSection}>
							<FileCorrectIcon className={styles.fileIcon} />
							<Body size="s" type="medium" className={styles.fileText}>
								{filesCount} {filesCount === 1 ? "file" : "files"}
							</Body>
						</div>

						<FileUploader
							trainingSet={trainingSet}
							refetchQuery={GET_TRAINING_SETS}
						/>
					</div>
				</div>
			</Card>

			<EditTrainingSetModal
				trainingSet={trainingSet}
				isOpen={isEditModalOpen}
				onClose={() => setIsEditModalOpen(false)}
			/>

			<ConfirmActionModal
				isOpen={isDeleteModalOpen}
				onClose={() => setIsDeleteModalOpen(false)}
				onConfirm={handleDelete}
				title="Delete Dataset"
				description="Do you really want to delete this dataset?"
				confirmText="Delete"
				cancelText="Cancel"
			/>
		</>
	)
};
