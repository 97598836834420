import {gql} from "@apollo/client";

export const GET_ORCH_OUTPUT_TYPES = gql`
	query GetAllOrchestrationOutputTypes {
		aiOrchestrationOutputTypes {
      id
			code
			name
			vurveyOnly
			createdAt
			updatedAt
		}
	}
`;

export const GET_AI_ORCHESTRATIONS = gql`
  query GetAllAiOrchestrations($workspaceId: String!, $input: AiOrchestrationsInput) {
    aiOrchestrations(workspaceId: $workspaceId, input: $input) {
      edges {
        node {
          id
          workspaceId
          name
          description
          aiPersonaTasks {
            persona {
              id
              name
              avatarUri
              picture {
                id
                url(version: THUMBNAIL)
              }
            }
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

// Get a single one, and all info (except workspace)
export const GET_AI_ORCHESTRATION = gql`
  query GetAiOrchestration($id: String!) {
    aiOrchestration(id: $id) {
      id
      workspaceId
      name
      description
      inputParameters
      instructions
      outputTypeId
      outputType {
        id
        code
        name
        vurveyOnly
        createdAt
        updatedAt
      }
      surveys {
        id
        name
      }
      trainingSets {
        id
        alias
      }
      aiPersonaTasks {
        id
        name
        instructions
        index
        smartPromptEnabled
        persona {
          id
          name
          avatarUri
          picture {
            id
            url(version: THUMBNAIL)
          }
        }
        task {
            id
            taskPrompt
            output
        }
      }
    }
  }
`;

export const GET_AI_ORCHESTRATION_HISTORY = gql`
  query GetAiOrchestrationHistory($orchestrationId: GUID!) {
    aiOrchestrationHistory(orchestrationId: $orchestrationId) {
      id
      aiOrchestrationId
      completedAt
      output
      status
      reportUrl
      reportWordUrl
      sources {
          surveyIds
          trainingSetIds
        }
      outputHistoryTasks {
        taskId
        persona {
          id
          name
          avatarUri
          picture {
            id
            url(version: THUMBNAIL)
          }
        }
        input
        output
        taskPrompt
      }
    }
  }
`;
