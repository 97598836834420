/* eslint-disable */
import React, {ReactElement, useContext, useMemo, useState} from "react";
import {useMutation} from "@apollo/client";
import {
	MultiSelect,
	Select,
} from "../../shared";
import {MANAGE_BRANDS} from "../../graphql/mutations/mutations";
import {PaginatedTable} from "../../shared/components/table/paginated-table";
import {Column, Row} from "react-table";
import {ToastContext} from "../../context/toast-context";
import {useLoadingQuery} from "../../hooks/useLoadingQuery";
import {GET_ADMIN_FEATURE_BRANDS} from "../../graphql/queries/queries";
import {
	Workspace,
	WorkspacePlan,
} from "../../models/workspace";
import {Panel} from "../../shared/components/panel";
import {SelectValue} from "../../shared/components/multi-select";
import {FilterDisplay} from "../components/filter-display";
import {
	BrandFilter,
	BrandsPageData,
	BrandsPageVars,
	ManageBrandChanges,
	ManageBrandData,
} from "../../models/brand";
import {Body, Button, DebounceSearch, Modal} from "../../shared/v2";
import styles from "./index.module.scss";
import {FilterIcon, SearchIcon} from "../../icons";

const TABLE_PAGE_SIZE = 100;

const BrandManagement = React.memo((): ReactElement => {
	const {updateToast} = useContext(ToastContext);
	// States
	const [filter, setFilter] = useState<BrandFilter>({});
	const [unsavedFilter, setUnsavedFilter] = useState<{
		aiInsightsEnabled?: string;
		allowAnonymous?: string;
		enableOfflineAssist?: string;
		plan?: WorkspacePlan[];
		visible?: string;
		isVerified?: string;
	}>({});
	const [changes, setChanges] = useState<{
		visible?: boolean;
		isVerified?: boolean;
	}>({});
	const [showFilter, setShowFilter] = useState(false);
	const [selectedBrands, setSelectedBrands] = useState<
	Row<Workspace>[]
	>([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [selectAll, setSelectAll] = useState(false);
	const [showBulkActions, setShowBulkActions] = useState(false);
	const [pageSize, setPageSize] = useState(TABLE_PAGE_SIZE);

	const toggleBulkActionModal = (): void => {
		if (showBulkActions) {
			setChanges({
				visible: undefined,
				isVerified: undefined,
			});
		}
		setShowBulkActions(!showBulkActions);
	};

	const numFiltersUsed = useMemo(() => {
		return Object.keys(unsavedFilter).length;
	}, [unsavedFilter]);

	const loadingQuery = useLoadingQuery<BrandsPageData, BrandsPageVars>(GET_ADMIN_FEATURE_BRANDS, {
		variables: {filter, limit: pageSize || 50},
		errorPolicy: "all",
		what: "Workspaces",
		fetchPolicy: "network-only",
		nextFetchPolicy: "cache-first",
		fetchMoreClassName: styles.fetchMore,
		notifyOnNetworkStatusChange: true,
	});
	const {data, error, refetch, fetchMoreFragment, fragment, handleFetchMore} =
		loadingQuery;
	/** ******************************
	 * Mutations start below
	 *********************************/
	const [setFeatures] = useMutation<ManageBrandData>(MANAGE_BRANDS, {
		onCompleted: changed => {
			updateToast({
				description: `${changed.manageBrands} brand(s) changed.`,
				type: "informational",
			});
			refetch();
		},
	});
	const updateFeatures = (changesToMake: ManageBrandChanges): void => {
		setFeatures({
			variables: {
				changes: changesToMake,
				useFilter: selectAll,
				filter,
				brandIds: selectedBrands.map(w => w.original.id),
			},
		});
	};
	/** ******************************
	 * End of Mutations
	 *********************************/

	const handleUpdate = (): void => {
		updateFeatures({...changes});

		toggleBulkActionModal();
		setSelectedBrands([]);
		setSelectAll(false);
	};

	const handleClearFilters = (): void => {
		setFilter({});
		refetch();
	};
	const handleRemoveFilter = (clearKey, clearValue?): void => {
		const newFilter = {...filter};

		if (Array.isArray(newFilter[clearKey])) {
			newFilter[clearKey] = newFilter[clearKey].filter(
				item => item !== clearValue,
			);
			if (newFilter[clearKey].length === 0) newFilter[clearKey] = undefined;
		} else {
			newFilter[clearKey] = undefined;
		}
		setFilter(newFilter);
		refetch();
	};
	const updateUnsavedFilter = (value, id: string): void => {
		setUnsavedFilter({...unsavedFilter, [id]: value});
	};
	const updateChanges = (value, id: string): void => {
		setChanges({...changes, [id]: value});
	};

	const currentData =

		useMemo(
			() =>
				data?.brands?.items?.slice(
					currentPage * pageSize,
					(currentPage + 1) * pageSize,
				),
			[data, currentPage],
		);
	const columns: Column<any>[] = [
		{
			id: "name",
			Header: "Brand Name",
			accessor: "name",
			minWidth: 250,
			Cell: ({value}) => <Body size="s">{value}</Body>,
		},
		{
			Header: "Visible",
			accessor: "visible",
			Cell: ({value}) => (
				<Body size="s">{value ? "Yes" : ""}</Body>
			),
		},
		{
			Header: "Verified",
			accessor: "isVerified",
			Cell: ({value}) => (
				<Body size="s">{value ? "Yes" : ""}</Body>
			),
		},
		{
			Header: "Workspace Name",
			accessor: "workspace.name",
			Cell: ({value}) => <Body size="s">{value}</Body>,
		},
		{
			Header: "Workspace Plan",
			accessor: "workspace.plan",
			Cell: ({value}) => <Body size="s">{value}</Body>,
		},
	];

	const isFiltered = Object.keys(filter).some(
		value => filter[value] !== undefined,
	);

	const handleSearch = (newValue: string): void => {
		setFilter({...filter, name: newValue});
		setCurrentPage(0); // In case there's only one page of results, want to start at the first page.
	};

	const handleBrandSearch = (newValue: string): void => {
		setFilter({...filter, brandName: newValue});
		setCurrentPage(0); // In case there's only one page of results, want to start at the first page.
	};

	const handlePerPageChange = (newPageSize: number): void => {
		setPageSize(newPageSize);
		setCurrentPage(0);
	}

	return (
		<>
			{error && (
				<Body size="s">
					Error loading workspace list: {error.message}
				</Body>
			)}
			{currentData &&
			currentData?.length === 0 &&
			!isFiltered &&
			!fetchMoreFragment ? (
					<></>
				) : (
					<header className={styles.header}>
						<div className={styles.searchWrapper}>
							<DebounceSearch
								id="search-brands"
								value={filter.brandName || ""}
								onChange={handleBrandSearch}
								placeholder="Search Brand Names"
								leftIcon={<SearchIcon />}
								className={styles.searchInput}
							/>

							<DebounceSearch
								id="search-workspaces"
								value={filter.name || ""}
								onChange={handleSearch}
								placeholder="Search Workspace Names"
								leftIcon={<SearchIcon />}
								className={styles.searchInput}
							/>
						</div>

						<Button
							leftIcon={<FilterIcon />}
							variant="outlined"
							onClick={() => {
								setUnsavedFilter({
									plan: filter.plan,
									enableOfflineAssist:
									filter.enableOfflineAssist === true
										? "On"
										: filter.enableOfflineAssist === false
											? "Off"
											: undefined,
									aiInsightsEnabled:
									filter.aiInsightsEnabled === true
										? "On"
										: filter.aiInsightsEnabled === false
											? "Off"
											: undefined,
									allowAnonymous:
									filter.allowAnonymous === true
										? "On"
										: filter.allowAnonymous === false
											? "Off"
											: undefined,
									visible:
									filter.visible === true
										? "Yes"
										: filter.visible === false
											? "No"
											: undefined,
									isVerified:
									filter.isVerified === true
										? "Yes"
										: filter.isVerified === false
											? "No"
											: undefined,
								});
								setShowFilter(!showFilter);
							}}
						>
							Filter
						</Button>
						<div className={styles.actions}>
							{selectedBrands.length > 0 && data && (
								<Button
									variant="outlined"
									onClick={toggleBulkActionModal}
								>
									{`Bulk Actions${
										selectAll
											? ` (${data.brands.items.length + data.brands.remaining})`
											: ` (${selectedBrands.length})`
									}`}
								</Button>
							)}
						</div>
					</header>
				)}
			<FilterDisplay
				filter={filter}
				handleClearFilters={handleClearFilters}
				handleRemoveFilter={handleRemoveFilter}
			/>
			{fragment ||
				(data && currentData && data.brands.items?.length > 0 ? (
					<>
						<PaginatedTable<any>
							columns={columns}
							data={currentData}
							selectedValues={selectedBrands}
							onSelectChange={setSelectedBrands}
							pageState={[currentPage, setCurrentPage]}
							pageSize={pageSize}
							totalCount={data.brands.items.length + data.brands.remaining}
							handleFetchMore={handleFetchMore}
							fetchMoreFragment={fetchMoreFragment}
							selectAllState={[selectAll, setSelectAll]}
							dataLength={data.brands.items?.length}
							onPageSizeChange={handlePerPageChange}
						/>
					</>
				) : (
					<p>No results found</p>
				))}
			{showFilter && (
				<Panel
					title="Filter"
					handleClose={() => setShowFilter(false)}
					theme="purple"
				>
					<div className={styles.sidebarContainer}>
						<div className={styles.sidebarProperties}>
							<Select
								id="visible"
								label="Visible"
								options={[
									{text: "Not filtered", value: undefined},
									{text: "Yes", value: "Yes"},
									{text: "No", value: "No"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "visible")
								}
								selectedValue={unsavedFilter.visible}
							/>
							<Select
								id="isVerified"
								label="Verified"
								options={[
									{text: "Not filtered", value: undefined},
									{text: "Yes", value: "Yes"},
									{text: "No", value: "No"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "isVerified")
								}
								selectedValue={unsavedFilter.isVerified}
							/>
							<Select
								id="aiInsightEnabled"
								label="AI Insights"
								options={[
									{text: "Not filtered", value: undefined},
									{text: "On", value: "On"},
									{text: "Off", value: "Off"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "aiInsightsEnabled")
								}
								selectedValue={unsavedFilter.aiInsightsEnabled}
							/>
							<Select
								id="allowAnonymous"
								label="Allow Anonymous"
								options={[
									{text: "Not filtered", value: undefined},
									{text: "On", value: "On"},
									{text: "Off", value: "Off"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "allowAnonymous")
								}
								selectedValue={unsavedFilter.allowAnonymous}
							/>
							<Select
								id="enableOfflineAssist"
								label="Offline Assist"
								options={[
									{text: "Not filtered", value: undefined},
									{text: "On", value: "On"},
									{text: "Off", value: "Off"},
								]}
								onChange={newValue =>
									updateUnsavedFilter(newValue, "enableOfflineAssist")
								}
								selectedValue={unsavedFilter.enableOfflineAssist}
							/>
							<MultiSelect
								id="plan"
								key="plan"
								value={unsavedFilter.plan || []}
								label="Workspace Plan"
								options={Object.keys(WorkspacePlan).map(
									(key): SelectValue<WorkspacePlan> => {
										const value = WorkspacePlan[key];
										return {name: value, id: value};
									},
								)}
								onChange={updateUnsavedFilter}
							/>
						</div>
						<div className={styles.sidebarFooter}>
							<Button
								onClick={() => {
									setFilter({
										plan: unsavedFilter.plan,
										enableOfflineAssist:
											unsavedFilter.enableOfflineAssist === "On"
												? true
												: unsavedFilter.enableOfflineAssist === "Off"
													? false
													: undefined,
										aiInsightsEnabled:
											unsavedFilter.aiInsightsEnabled === "On"
												? true
												: unsavedFilter.aiInsightsEnabled === "Off"
													? false
													: undefined,
										allowAnonymous:
											unsavedFilter.allowAnonymous === "On"
												? true
												: unsavedFilter.allowAnonymous === "Off"
													? false
													: undefined,
										visible:
											unsavedFilter.visible === "Yes"
												? true
												: unsavedFilter.visible === "No"
													? false
													: undefined,
										isVerified:
											unsavedFilter.isVerified === "Yes"
												? true
												: unsavedFilter.isVerified === "No"
													? false
													: undefined,
									});
									setShowFilter(false);
								}}
							>
								{
									numFiltersUsed
										? `Apply Filters (${String(numFiltersUsed)})`
										: "Apply Filters"
								}
							</Button>
							<Button
								variant="outlined"
								onClick={() => {
									setFilter({});
									setShowFilter(false);
								}}
							>
								Clear Filters
							</Button>
						</div>
					</div>
				</Panel>
			)}
			<Modal
				isOpen={showBulkActions}
				onClose={toggleBulkActionModal}
				title={`Updating ${
					selectAll
						? (data?.brands?.items?.length ?? 0) +
							(data?.brands?.remaining ?? 0)
						: selectedBrands.length
				} Workspaces`}
			>
				<div>
					<Select
						className={styles.modelSelect}
						id="changesVisible"
						label="Visible"
						options={[
							{text: "Do not change", value: undefined},
							{text: "Yes", value: true},
							{text: "No", value: false},
						]}
						onChange={newValue =>
							updateChanges(newValue, "visible")
						}
						selectedValue={changes.visible}
					/>
					<Select
						className={styles.modelSelect}
						id="changesIsVerified"
						label="Verified"
						options={[
							{text: "Do not change", value: undefined},
							{text: "Yes", value: true},
							{text: "No", value: false},
						]}
						onChange={newValue => updateChanges(newValue, "isVerified")}
						selectedValue={changes.isVerified}
					/>
				</div>
				<div className={styles.modelFooter}>
					<Button
						variant="outlined"
						onClick={toggleBulkActionModal}
					>
						Cancel
					</Button>
					<Button
						onClick={handleUpdate}
					>
						Update
					</Button>
				</div>
			</Modal>
		</>
	);
});

BrandManagement.displayName = "BrandManagement";
export {BrandManagement};
