import React, {ReactElement} from "react";
import {Body, ButtonIcon, Tooltip} from "@/shared/v2";
import {Badge} from "@/workspace-settings/components/model-card/badge";
import {FileEmbeddingsGenerationStatus, TrainingSetMedia} from "@/models/ai-model";
import {isTrainingVideo} from "@/datasets/containers/dataset-page";
import {ReloadArrowIcon} from "@/icons";

import styles from "./file-status.module.scss";

export interface FileStatusProps {
  row: TrainingSetMedia;
  handleRetryTranscoding?: (row: TrainingSetMedia) => void;
}


export const FileStatus = ({row, handleRetryTranscoding}: FileStatusProps): ReactElement | null => {
  switch (row.embeddingsGenerationStatus) {
    case FileEmbeddingsGenerationStatus.FAILED:
      return (
        <div className={styles.failedBadgeWrapper}>
          <Badge text={FileEmbeddingsGenerationStatus.FAILED} />
          {handleRetryTranscoding && (
            <Tooltip
              placement="top"
              content={
                <Body size="xs">
                  Reload {isTrainingVideo(row) ? "Video" : "File"}
                </Body>
              }
            >
              <ButtonIcon
                size="small"
                icon={<ReloadArrowIcon />}
                onClick={() => handleRetryTranscoding(row)}
              />
            </Tooltip>
          )}
        </div>
      );
    case FileEmbeddingsGenerationStatus.SUCCESS:
      return <Badge text={FileEmbeddingsGenerationStatus.SUCCESS} />;
    case FileEmbeddingsGenerationStatus.UPLOADED:
      return <Badge text={FileEmbeddingsGenerationStatus.UPLOADED} />;
    case FileEmbeddingsGenerationStatus.PROCESSING:
      return (
        <Tooltip
          content={
            <Body size="xs">
              {isTrainingVideo(row) ? "Video" : "File"} is being processed. We&apos;ll let you know when its ready.
            </Body>
          }
        >
          <Badge text={FileEmbeddingsGenerationStatus.PROCESSING} />
        </Tooltip>
      );
    default:
      return null;
  }
}
