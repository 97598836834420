import React, {ReactElement} from "react";

import {DocumentsFolderFileIcon, FileTextIcon} from "@/icons";
import {ButtonGroup, ButtonGroupElement} from "@/shared/components/navigation";
import {SelectSourcesPage, useNavigationContext} from "../../contexts/navigation-context";

export const SourcesNavigation = (): ReactElement => {
  const {currentPage, setCurrentPage} = useNavigationContext();

  return (
    <ButtonGroup>
      <ButtonGroupElement
        icon={<DocumentsFolderFileIcon />}
        text="Datasets"
        active={
          currentPage === SelectSourcesPage.DATASETS ||
          currentPage === SelectSourcesPage.DATASET_MEDIA
        }
        onClick={() => setCurrentPage(SelectSourcesPage.DATASETS)}
      />
      <ButtonGroupElement
        icon={<FileTextIcon />}
        text="All files"
        active={currentPage === SelectSourcesPage.MEDIA}
        onClick={() => setCurrentPage(SelectSourcesPage.MEDIA)}
      />
    </ButtonGroup>
  );
}
