import React, {ReactElement, ReactNode, createContext, useEffect, useRef} from "react";

import {useChatConversationContext} from "@/context/chat-contexts";
import {useNavigationContext} from "./navigation-context";
import {useSearchParams} from "react-router-dom";
import {useSelectedSourcesContext} from "./selected-sources-context";
import {useTask} from "@/hooks/useTask";
import {useToastContext} from "@/context/toast-context";

export interface ActionsContextValue {
  submit: () => void;
  isSubmittingRef: React.MutableRefObject<boolean>;
}

export const ActionsContext =
  createContext<ActionsContextValue | undefined>(undefined);

export const ActionsContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {conversation} = useChatConversationContext();
  const {updateToast} = useToastContext();
  const {close} = useNavigationContext();
  const {save: saveSelectedSources} = useSelectedSourcesContext();
  const [searchParams] = useSearchParams();
  const shouldNextChangeInSearchParamsTriggerClose = useRef(false);

  useEffect(() => {
    if (shouldNextChangeInSearchParamsTriggerClose.current) {
      close();
    }
  }, [searchParams]);

  const {run: submit, loadingRef: isSubmittingRef} = useTask(async () => {
    try {
      if (!conversation) {
        shouldNextChangeInSearchParamsTriggerClose.current = true;
      }

      await saveSelectedSources();

      if (conversation) {
        close();
      }
    } catch (error) {
      shouldNextChangeInSearchParamsTriggerClose.current = false;
      updateToast({
        description: "Error while saving training set ids",
        type: "failure",
      });
      console.error("Error while saving training set ids", error);
    }
  });

  return (
    <ActionsContext.Provider value={{submit, isSubmittingRef}}>
      {children}
    </ActionsContext.Provider>
  );
};

export const useActionsContext = (): ActionsContextValue => {
  const context = React.useContext(ActionsContext);

  if (context === undefined) {
    throw new Error(
      "useActionsContext must be used within a ActionsContextProvider",
    );
  }

  return context;
};
