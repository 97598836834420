import React, {ReactElement, useMemo} from "react";
import {Outlet, useLocation} from "react-router";

import {ButtonGroup, ButtonGroupElement} from "../../../shared/components/navigation";
import {ModelsIcon, SparkAiStarsIcon} from "../../../icons";
import {PageSubNavigation} from "../../../hoc/layout/layout/page-sub-navigation";
import {NavigationPageLayout} from "../../../hoc/layout/navigation-page-layout";
import {NavigationTooltip} from "../../../hoc/layout/navigation/navigation-tooltip";
import {useMount} from "@/hooks/useMount";

export const WorkflowPage = (): ReactElement => {
	const location = useLocation();

	useMount(() => {document.title = "Vurvey - Workflow"})

	//const displaySubNavigation = useMemo(() => {
	//	return !location.pathname.includes("/workflow/flows")
	//}, [location.pathname])
	return <NavigationPageLayout>
		{true &&
			<PageSubNavigation header="Workflow">
				<ButtonGroup>
					<NavigationTooltip
						text="Create workflows to automate the creation of new outputs"
					>
						<ButtonGroupElement
							icon={<ModelsIcon />}
							text="Workflows"
							to=""
							isBeta
							end
						/>
					</NavigationTooltip>
                    <NavigationTooltip
						text="Check your conversations"
					>
						<ButtonGroupElement
							icon={<SparkAiStarsIcon />}
							text="Conversations"
							to="conversations"
                            end
						/>
					</NavigationTooltip>
					{/* <NavigationTooltip
						text="See the results of your workflows"
					>
						<ButtonGroupElement
							icon={<HandOutputStarIcon />}
							text="Outputs"
							to="outputs"
						/>
					</NavigationTooltip> */}
				</ButtonGroup>
			</PageSubNavigation>
		}

		<Outlet />
	</NavigationPageLayout>
}
