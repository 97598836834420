import React, {ReactElement, ReactNode, createContext} from "react";

export interface SearchContextValue {
  search: string;
  setSearch: (search: string) => void;
}

export const SearchContext =
  createContext<SearchContextValue | undefined>(undefined);

export const SearchContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const [search, setSearch] = React.useState("");

  return (
    <SearchContext.Provider value={{search, setSearch}}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = (): SearchContextValue => {
  const context = React.useContext(SearchContext);

  if (context === undefined) {
    throw new Error(
      "useSearchContext must be used within a SearchContextProvider",
    );
  }

  return context;
};
