import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {useThemeMode} from "@/context/theme-mode-context";
import {TrainingSet} from "@/models";
import {Checkbox, Subheader, Body} from "@/shared/v2";

import styles from "./training-card-select.module.scss";

const cx = classNames.bind(styles);

export interface TrainingCardSelectProps {
	trainingSet: TrainingSet;
	onSelected: (trainingSet: TrainingSet) => void;
	isSelected: boolean;
}

export const TrainingCardSelect = ({
	trainingSet,
	onSelected,
	isSelected,
}: TrainingCardSelectProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	return (
		<button
			className={cx("trainingCardSelect", {selected: isSelected, isDarkMode})}
			onClick={() => onSelected(trainingSet)}
		>
			<div className={styles.headerWrapper}>
				<Checkbox
					id={trainingSet.id}
					checked={isSelected}
					onChange={() => onSelected(trainingSet)}
					text={(
						<Subheader
							color="text-body"
							type="semibold"
							className={styles.trainingSetName}
						>
							{trainingSet.alias}
						</Subheader>
					)}
				/>
			</div>

			<Body
				className={styles.trainingSetDescription}
				color="text-secondary"
				size="s"
			>
				{trainingSet.description}
			</Body>
		</button>
	);
};
