import React, {ReactElement, useEffect, useRef} from "react";
import classNames from "classnames/bind";

import {AiPersonaTask} from "../../../models/ai-orchestration";
import {Avatar, Body, Input, Subheader, TextArea, Switch} from "../../../shared/v2";
import {CloseMediumIcon} from "../../../icons";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useWorkflowContext} from "../../../context/workflow-contexts/workflow-context";
import config from "../../../config";
import {Tooltip} from "../../../shared/v2/tooltip";

import styles from "./agent-task-card.module.scss";
import {NoDraggableItem} from "../no-draggable-item";

const bStyles = classNames.bind(styles);

export interface AgentTaskCardProps {
	agentTask: AiPersonaTask;
	onRemove: (agent: AiPersonaTask) => void;
	onChangeTask: (value: string) => void;
	onChangeIndex: (agent: AiPersonaTask, newIndex: number) => void;
	disabled?: boolean;
	elementRef?: any;
	agentsLength?: number;
}

export const AgentTaskCard = ({
	agentTask,
	onRemove,
	onChangeTask,
	onChangeIndex,
	disabled,
	elementRef,
	agentsLength
}: AgentTaskCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {setAgentTasks, isWorkflowRunning} = useWorkflowContext();
	const taskRef = useRef<HTMLTextAreaElement>(null);
	const orderRef = useRef<HTMLInputElement>(null);

	// prevent zooming when scrolling or typing
	useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      event.stopPropagation();
    };

		const handleWheel = (event: WheelEvent) => {
      event.stopPropagation();
    };

    const taskElement = taskRef.current;
    if (taskElement) {
      taskElement.addEventListener("mousedown", handleMouseDown);
			taskElement.addEventListener("wheel", handleWheel);

    }

		const orderElement = orderRef.current;
		if (orderElement) {
			orderElement.addEventListener("mousedown", handleMouseDown);
		}

    return () => {
      if (taskElement) {
        taskElement.removeEventListener("mousedown", handleMouseDown);
				taskElement.removeEventListener("wheel", handleWheel);

      }
			if (orderElement) {
				orderElement.removeEventListener("mousedown", handleMouseDown);
			}
    };
  }, []);

	const handleChangeTask = (value: string) => {
		onChangeTask(value);
	};

	const handleRemove = () => {
		if (isWorkflowRunning) {
			return;
		}
		onRemove(agentTask);
	};

	const handleSmartPromptChange = (checked: boolean) => {
		setAgentTasks(prevTasks => {
			return prevTasks.map(task => {
				if (task.id === agentTask.id) {
					return {
						...task,
						smartPromptEnabled: checked,
						operation: task.operation || "UPDATE"
					};
				}
				return task;
			});
		});
	};

	const renderAgentName = (task: AiPersonaTask) => {
		if (task.persona?.name.includes("auto")) {
			return task.persona.name.replace(/-auto-.*$/, "").split(/(?=[A-Z])/).join(" ")
		};

		return task.persona?.name || "";
	}

	const cardStyles = bStyles("card", {
		processing: agentTask.processingState?.toLowerCase().includes("processing") || 
		            agentTask.processingState?.toLowerCase().includes("[completing]"),
		completed: agentTask.processingState?.toLowerCase().includes("[completed]"),
		isDarkMode,
	});

	return <NoDraggableItem className={styles.noDraggableWrapper}>
		<div className={cardStyles} ref={elementRef} id={agentTask.id}>
			<div className={styles.topSection}>
				<Avatar
					key={agentTask.persona?.id}
					size="xxl"
					firstName={agentTask.persona?.name}
					url={agentTask.persona?.picture?.url || agentTask.persona?.avatarUri}
					className={styles.avatar}
				/>
				<div className={styles.generalInfo}>
					<Subheader className={styles.name} type="semibold">
						{renderAgentName(agentTask)}

					</Subheader>
					<Body size="s" color="text-secondary">
						{agentTask.persona?.personaType?.name}
					</Body>
				</div>
				{!disabled &&
					<CloseMediumIcon onClick={handleRemove} className={bStyles("closeIcon", {isWorkflowRunning})} />
				}
			</div>

			<TextArea
				className={styles.task}
				value={agentTask.task?.taskPrompt || ""}
				onChange={handleChangeTask}
				label="Task"
				disabled={isWorkflowRunning || disabled}
				refProp={taskRef}
			/>

			<div className={styles.bottomSection}>
				{config.featureToggles.smartPrompt && (
					<Tooltip
						content={
							<div style={{maxWidth: "300px"}}>
								Advanced Reasoning will try to use the appropiate Tools, Datasets, or Image functionalities to best generate an appropriate response. This can be toggled off if you wish to only use the Tools explicitly (via the '/' commands)
							</div>
						}
					>
						<div className={styles.smartPromptControl}>
							<Body color="text-secondary" size="s" type="medium">Advanced Reasoning</Body>
							<Switch
								checked={agentTask.smartPromptEnabled || false}
								onChange={handleSmartPromptChange}
								disabled={isWorkflowRunning || disabled}
							/>
						</div>
					</Tooltip>
				)}
				<Input
					type="number"
					label="Order"
					value={((agentTask.index ?? 0) + 1).toString()}
					onChange={value => {
						const newIndex = parseInt(value, 10) - 1;
						if (!isNaN(newIndex) && newIndex >= 0) {
							onChangeIndex(agentTask, newIndex);
						}
					}}
					min={1}
					max={agentsLength}
					disabled={isWorkflowRunning || disabled}
					className={styles.indexInput}
					refProp={orderRef}
				/>
			</div>
		</div>
	</NoDraggableItem>
};
