import React, {ReactElement, useMemo} from "react";

import {Campaign, TrainingSetFile, TrainingSetMedia, TrainingSetVideo} from "../../../models/ai-model";
import {DocumentsFolderFileIcon, FileTextIcon, FileVideoIcon, HornMegaphoneCampaignIcon} from "../../../icons";
import {TrainingSet} from "../../../models";
import {useChatCampaignContext, useChatSourcesContext} from "../../../context/chat-contexts";

import styles from "./sources-section.module.scss";
import {InputChip} from "../../../shared/components/input-chip";


export const isTrainingSet = (source: TrainingSet | TrainingSetMedia | Campaign): source is TrainingSet => {
	return "alias" in source;
}

export const isTrainingSetFile = (source: TrainingSet | TrainingSetMedia | Campaign): source is TrainingSetFile => {
	return "__typename" in source && source.__typename === "File";
}

export const isTrainingSetVideo = (source: TrainingSet | TrainingSetMedia | Campaign): source is TrainingSetVideo => {
	return "__typename" in source && source.__typename === "TrainingSetVideo";
}

export const SourcesSection = (): ReactElement => {
	const {
		active: {
			trainingSets: activeTrainingSets,
			media: activeMedia,
		},
		save: saveSources,
		isUpdatingDisabled: isSourcesUpdatingDisabled,
	} = useChatSourcesContext();
	const {
		activeCampaigns,
		saveCampaigns,
		isUpdatingDisabled: isCampaignUpdatingDisabled,
	} = useChatCampaignContext();

	const handleRemoveTrainingSet = (trainingSet: TrainingSet) => {
		saveSources({
			trainingSets: activeTrainingSets?.filter(ts => ts.id !== trainingSet.id).map(ts => ts.id) || [],
		})
	}

	const handleRemoveVideo = (video: TrainingSetVideo) => {
		saveSources({
			media: {
				videos: activeMedia.videos?.filter(v => v.id !== video.id).map(v => v.id) || [],
			},
		});
	}

	const handleRemoveFile = (file: TrainingSetFile) => {
		saveSources({
			media: {
				files: activeMedia.files?.filter(f => f.id !== file.id).map(f => f.id) || [],
			},
		});
	}

	const handleRemoveCampaign = (campaign: Campaign) => {
		saveCampaigns(activeCampaigns.filter(c => c.id !== campaign.id).map(c => c.id));
	}

	const sources = useMemo(() => {
		return [...(activeTrainingSets || []), ...activeCampaigns, ...activeMedia.files, ...activeMedia.videos];
	}, [activeTrainingSets, activeMedia, activeCampaigns]);

	const renderSource = (source: TrainingSet | TrainingSetMedia | Campaign) => {
		if (isTrainingSet(source)) {
			return (
				<InputChip
					className={styles.source}
					icon={<DocumentsFolderFileIcon />}
					key={source.id}
					onRemove={() => handleRemoveTrainingSet(source)}
					disabled={isSourcesUpdatingDisabled}
					label={source.alias}
					variant="squared"
					size="small"
				/>
			);
		}
		if (isTrainingSetVideo(source)) {
			return (
				<InputChip
					variant="squared"
					className={styles.source}
					icon={<FileVideoIcon />}
					key={source.id}
					disabled={isSourcesUpdatingDisabled}
					onRemove={() => handleRemoveVideo(source)}
					label={source.originalFilename}
					size="small"
				/>
			);
		}
		if (isTrainingSetFile(source)) {
			return (
				<InputChip
					variant="squared"
					className={styles.source}
					icon={<FileTextIcon />}
					key={source.id}
					disabled={isSourcesUpdatingDisabled}
					onRemove={() => handleRemoveFile(source)}
					label={source.originalFilename}
					size="small"
				/>
			);
		}

		return (
			<InputChip
				variant="squared"
				className={styles.source}
				icon={<HornMegaphoneCampaignIcon />}
				key={source.id}
				disabled={isCampaignUpdatingDisabled}
				onRemove={() => handleRemoveCampaign(source)}
				label={source.name}
				size="small"
			/>
		);
	};

	if (!sources.length) {
		return <></>;
	}

	return (
		<div className={styles.sourcesSection}>
			{sources.map(renderSource)}
		</div>
	);
}
