import {TrainingSetFile, TrainingSetVideo} from "@/models/ai-model";
import React, {ReactElement, ReactNode, createContext, useEffect, useMemo, useState} from "react";
import {useChatConversationContext} from "./chat-conversation-context";
import {useSearchParams} from "react-router-dom";
import {useChatTrainingSetContext} from "./chat-training-set-context";

export interface ChatMedia {
  files: TrainingSetFile[];
  videos: TrainingSetVideo[];
}

export type ChatMediaUpdate = Partial<Record<keyof ChatMedia, string[]>>;

export interface ChatMediaContextValue {
  isLoading: boolean;
  media: ChatMedia;
  activeMedia: ChatMedia;
}

export const ChatMediaContext =
  createContext<ChatMediaContextValue | undefined>(undefined);

export const ChatMediaContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {
    conversation,
    isLoading: isLoadingConversation,
  } = useChatConversationContext();
  const {
    trainingSets,
    isLoading: isLoadingTrainingSets,
  } = useChatTrainingSetContext();
  const [searchParams] = useSearchParams();
  const [activeMedia, setActiveMedia] = useState<ChatMedia>({files: [], videos: []});

  const isLoading = Boolean(isLoadingConversation || isLoadingTrainingSets);

  const media: ChatMedia = useMemo(() => {
    return trainingSets
      .reduce((acc, {files, videos}) => {
        acc.files.push(...files);
        acc.videos.push(...videos);
        return acc;
      }, {files: [], videos: []} as ChatMedia);
  }, [trainingSets]);

  const savedMedia = useMemo(() => {
    if (conversation) {
      return {
        files: conversation.files,
        videos: conversation.videos,
      };
    } else {
      const fileIds = searchParams.get("fileIds")?.split(",") || [];
      const videoIds = searchParams.get("videoIds")?.split(",") || [];

      return {
        files: media.files.filter(({id}) => fileIds.includes(id)),
        videos: media.videos.filter(({id}) => videoIds.includes(id)),
      }
    }
  }, [conversation, media, searchParams]);

  useEffect(() => {
    setActiveMedia(savedMedia);
  }, [savedMedia]);

  return (
    <ChatMediaContext.Provider value={{
      isLoading,
      media,
      activeMedia,
    }}>
      {children}
    </ChatMediaContext.Provider>
  );
};

export const useChatMediaContext = (): ChatMediaContextValue => {
  const context = React.useContext(ChatMediaContext);

  if (context === undefined) {
    throw new Error(
      "useChatMediaContext must be used within a ChatMediaContextProvider",
    );
  }

  return context;
};
